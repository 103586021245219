<h1 mat-dialog-title>
    <span>
        {{data.title}}
    </span>
</h1>
<div mat-dialog-content>
    <span>{{data.menssage}}</span>
</div>
<div mat-dialog-actions dir=rtl fxLayoutGap="20px">
    <button mat-raised-button [mat-dialog-close]="data.buttonLeft.value"
        tabindex="-1">{{data.buttonLeft.label}}</button>
    <button  mat-stroked-button [mat-dialog-close]="data.buttonRight.value"
        tabindex="-1">{{data.buttonRight.label}}</button>
</div>