import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: AuthService
  ) { }
  token: string;
  public load = true;

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    localStorage.setItem('tokenAutentic', this.token);
    this.service.postToken(this.token).subscribe(
      (res: any) => {
        this.load = false;
        if (res.success && res.msg === 'ok') {
          localStorage.setItem('user', res.user);
          localStorage.setItem('username', res.username);
          localStorage.setItem('email', res.email);
          localStorage.setItem('image', res.image);
          localStorage.setItem('client', res.client);
          localStorage.setItem('state', res.msg);
          localStorage.setItem('token', res.token);
          localStorage.setItem('domain', res.domain);
          localStorage.setItem('sentences', '{}');
          this.router.navigate(['sentences']);
        } else {
          localStorage.setItem('state', res.msg);
          this.router.navigate(['sentences']);
        }
      },
      (error: any) => {
        this.load = false;
        localStorage.setItem('state', error.statusText);
        this.router.navigate(['sentences']);
      }
    );
  }

}
