import { Component, OnInit, Inject, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data/data.service';
import { NoticeComponent } from '../notice/notice.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {

  @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;

  componentRef: ComponentRef<any>;

  public dados: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogComponent>,
    private resolver: ComponentFactoryResolver,
    private service: DataService,
    private notice: NoticeComponent
  ) {
    this.dados = data;
  }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);
    this.componentRef.instance.data = this.data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // saveSentence(payload, id?) {
  //   console.log(payload, id);
  //   this.service.resolve('saveSentence/'+id, payload).then((data) => {
  //     if (data.success) {
  //       this.notice.showMessageSuccess(data);
  //       this.dialogRef.close();
  //     } else {
  //       this.notice.showMessageError(data);
  //       this.dialogRef.close();
  //     }
  //   });
  // }

  // saveExecuteSentence(payload, id) {
  //   this.service.resolve(id, payload);
  // }
}
