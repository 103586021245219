import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {
  [x: string]: any;

  constructor(private router: Router) { }

  private client = localStorage.getItem('client');
  private user = localStorage.getItem('user');

  canActivate() {
    if (this.client && this.user) {
      return true;
    } else {
      this.router.navigateByUrl('/notauthorized');
      return false;
    }
  }
}
