import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import { CrudLogService } from "../crud-log/crud-log.service";
import { DialogLogCrudComponent } from '../dialog/template/dialog-log-crud/dialog-log-crud.component';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-log-integration',
  templateUrl: './log-integration.component.html',
  styleUrls: ['./log-integration.component.scss']
})
export class LogIntegrationComponent implements OnInit {

  public dataSource: any[] = new Array();
  private payload: object;

  

  sql: any[] = [];
  method: any[] = [];
  date: Date[] = [];
  response: any[] = [];
  

  public noSentences: boolean = false;
  public hasSentences: boolean = false;
  public loadingColumns = 0;
  public channels: any;
  public events: any;
  public intervals: any[] = new Array();

  formulario: FormGroup;

  filters: any[] = [

    {
      header: 'ID',
      columnProp: 'id',
    },

    {
      header: 'SQL',
      columnProp: 'sentence',
    },
    {
      header: 'Data',
      columnProp: 'created_at',
      data: true
    },
    {
      header: 'Método',
      columnProp: 'uri',
    },
    {
      header: 'Request',
      columnProp: 'request',
    },
    {
      header: 'Response',
      columnProp: 'response',
    },
  ];
  
  columns: any[] = [
    {columnDef: 'id', header: 'ID', dataName: row => `${row.id}`},
    {columnDef: 'sentence', header: 'SQL', dataName: row => `${row.sentence}`},
    {columnDef: 'uri', header: 'Método', dataName: row =>`${row.uri}`},
    {columnDef: 'created_at', header: 'Data', dataName: row => `${row.created_at.split(' ')[0].split('-').reverse().join('/') + "  às  " + row.created_at.split(' ')[1].split(':').join(':')}`},
    {columnDef: 'request', header: 'Request', dataName: row => `${row.request}`},
    {columnDef: 'response', header: 'Response', dataName: row => `${row.response}`},
    {columnDef: 'options', dataName: row => `${row}`}
    ];

  triggerFilter: boolean;
  displayFilter: boolean;


    constructor(
      private service: DataService,
      private router: Router,
      private dialog: MatDialog,
      //importação da classe do arquivo Service do crud-log
      private crudService: CrudLogService
    ) { }

  ngOnInit() {

    const state = localStorage.getItem('state');

    if (state != 'ok') {
      this.router.navigate(['']);
    }

    this.payload = {
      client : localStorage.getItem('client'),
      user : localStorage.getItem('user'),
      jwt : localStorage.getItem('token')
    };
      this.getLogDataColumns();
  }

  spliceCrud(id) {

    for (const [index, item] of this.dataSource.entries()) {
      if (item.id == id) {
        this.dataSource.splice(index, 1);
        this.crudService.deleteRowData(id);
      }
    }
  }
  

  getLogDataColumns() {
    this.loadingColumns = 0;

    this.service.resolve('getLogData', this.payload).then((obj) => {
      if (obj.data.length != 0) {
        
        obj.data.map((element) => { 

          if (element.uri == null) {
            element.uri = " api/ Não foi possível localizar o caminho";
          }

          var index =  element.uri.indexOf('api/');
          

          let size = element.uri.length;
          let arrayFile =  element.uri.split('/');

          element.uri = element.uri.slice(index, size).toString();


          //corte na string da coluna file          
          element.uri = arrayFile.length <= 4 ? 'Consulta TOTVS' : element.uri;

          //Alteração no front para diferenciar quando chegou apenas no totvs a consulta
          element.request = ((element.request == null || element.request == 1 || element.request == 0)) ? 'Consulta TOTVS' : element.request
          element.response = ((element.response == null || element.response == 0)) ? 'Consulta TOTVS' : element.response
        
          if(element.uri == 'g'){

            element.uri = 'Consulta TOTVS'
          }
          
        })
        this.dataSource = obj.data;
        this.hasSentences = true;
        this.loadingColumns += 1;

      } else {
        this.dataSource = obj.data;
        this.noSentences = true;
        this.loadingColumns = 0;
      }

    });
  }




  openDialogLogCrud(logdata) {
    this.dialog.open(DialogLogCrudComponent, {
      width: '600px',
      maxHeight: '600px',
      panelClass: 'rbDefaultIsolatedModal',
      data: {
        titleDialog: 'LOG',
        component: DialogLogCrudComponent,
        data: logdata,
        payload: this.payload,
      }
    });
  }

  getLogData(row: any) {
    let response: any;
    this.service.resolve(
      'viewLog/', [row, this.payload]).then((obj: any) => {
        if(obj && obj.data) {
          response = obj.data;
          this.openDialogLogCrud(response);
        }
    });


  }

}
