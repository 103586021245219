import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NoticeComponent } from 'src/app/components/notice/notice.component';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-dialog-category',
  templateUrl: './dialog-category.component.html',
  styleUrls: ['./dialog-category.component.scss']
})
export class DialogCategoryComponent implements OnInit {

  formCategory: FormGroup;
  public dados: any = [];

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notice: NoticeComponent,
    public dialogRef: MatDialogRef<DialogCategoryComponent>,
    private service: DataService,
  ) {
    this.dados = data;
    this.formCategory = this.formBuilder.group({
      id: [ this.data.data != null ? this.data.data.id : null ],     
      titulo: [ this.data.data != null ? this.data.data.titulo : null, Validators.required ],                                                                                
    });
  }
  

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
}
