<!--The content below is only a placeholder and can be replaced.-->
<app-loader></app-loader>
<mat-toolbar id="toolbar">
    <a href="/sentences">
        <img fxLayoutAlign="center center" src="../assets/icons/integracaototvs.svg" class="rubicon" style="padding: 5px;">
    </a>
    <div class="vertical-line"></div>
    <span style="color:#4d4d4d;" fxFlex="85">INTEGRAÇÃO TOTVS</span>

    <nav *ngIf="client && user" mat-tab-nav-bar fxLayoutAlign="start center">
        <a mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive #rla="routerLinkActive"
            [active]="rla.isActive">

            {{link.label}}
        </a>
    </nav>



    <button *ngIf="client && user" class="btn-menu" mat-button [matMenuTriggerFor]="menu" >
        <span class="name">{{username}}</span> 
        <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="gotoCrm()"><mat-icon class="opticon" svgIcon="exit"></mat-icon>Sair</button>
    </mat-menu>

</mat-toolbar>
<br><br>
<!-- 

<div class="wrapper">
    <div class="toolbar">
        <mat-toolbar fxLayout="row" class="ps-nav" >
        <div fxFlex fxLayoutAlign="start center">
            <a [routerLink]="['']" class="ps-nav-logo">
            <img src="../assets/icons/logo.svg">
            </a>
            <span class="ps-nav-separator"></span>
            <span class="ps-nav-name-project">
            <a [routerLink]="['']">
                Integração TOTVS
            </a>
            </span>
        </div>
        <div fxFlex fxLayoutAlign="end center" fxFill>
            <button 
            mat-button class="ps-nav-button" 
            [routerLink]="['/administrativo/home']"
            routerLinkActive="ps-nav-button-active">
            Processos
            </button>
            <button 
            mat-button class="ps-nav-button" 
            [routerLink]="['/administrativo/definicoes/']"
            routerLinkActive="ps-nav-button-active"
            (click)="navigate()">
            Configurações
            </button>
            <button 
            mat-button 
            [matMenuTriggerFor]="menu" 
            class="ps-nav-button ps-nav-button-name" 
            #MatMenuTrigger
            (click)="someMethod()">
            William
            <mat-icon class="ps-nav-icon-right">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item>
                <span>Meus dados</span>
            </button>
            <button mat-menu-item>
                <span>Sobre</span>
            </button>
            <button mat-menu-item>
                <span>Sair</span>
            </button>
            </mat-menu>
        </div>
        </mat-toolbar>
    </div>
</div> -->
<router-outlet></router-outlet>