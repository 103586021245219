<div mat-dialog-title class="ps-header-dialog" fxLayoutAlign="start center">
    <div fxFlex="" fxLayoutAlign="start center" fxLayout="row" class="ps-dialog-title">
        <div fxFlex="">{{dados.titleDialog}}</div>
        <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="container">
    <ul  cdkDropList class="rb_columns_list"  (cdkDropListDropped)="drop($event)">
        <li class="rb_columns_line" fxLayout="row" fxLayoutAlign="center center" *ngFor="let column of columns.columns; let i = index" cdkDrag >

            <mat-icon class="rb_drag_indicator_icon">drag_indicator</mat-icon>
            <div fxFlex="">{{column.header}}</div>
            <mat-icon class="olho"  style="color: #A1A1A1;" (click)="hideColumn(column, i)" > {{ column.visibility ? "visibility" : "visibility_off" }}</mat-icon>

        </li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
        <a #changeButtonHover mat-stroked-button (click)="resetColumns()">Restaurar</a>
        <a mat-raised-button (click)="ModifyColumns()">Aplicar</a>
</mat-dialog-actions>




