import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogCategoryComponent } from '../dialog/template/dialog-category/dialog-category.component';
import { DialogDeleteComponent } from '../dialog/template/dialog-delete/dialog-delete.component';
import { NoticeComponent } from '../notice/notice.component';

@Component({
  selector: 'app-crud-category',
  templateUrl: './crud-category.component.html',
  styleUrls: ['./crud-category.component.scss']
})
export class CrudCategoryComponent implements OnInit {
  displayedColumns: string[] = ['id', 'nome', 'quantidade', 'editButton', 'deleteButton'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  mSort: any;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.mSort = sort;
  }

  pageIndex = 0;
  pageSize = 25;
  categories: any;
  private payload: object;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private service: DataService,
    private notice: NoticeComponent
  ) {
    this.categories = route.snapshot.data.categories;
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.categories);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.pageSize;
    this.dataSource.paginator.pageIndex = this.pageIndex;

    this.payload = {
      client: localStorage.getItem('client'),
      user: localStorage.getItem('user'),
      jwt: localStorage.getItem('token')
    };
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  functionCategoryCreate(row=null) {
    const dialog = this.dialog.open(DialogCategoryComponent, {
      width: '350px',
      panelClass: 'rbDefaultIsolatedModal',
      data: {
        titleDialog: 'Categoria',
        component: DialogCategoryComponent,
        data: row,
        payload: this.payload
      }
    });

    dialog.afterClosed().subscribe(
      category => {
        console.log(category);
        if (category) {
          //Salvar categoria criada
          console.log(category)
          this.savacategory(category, category.id);
        } else {
          //Cancelou
        }

      }
    );
  }

  // functionCrudEdit(row) {
  //   const dialog = this.dialog.open(DialogComponent, {
  //     width: '750px',
  //     minHeight: '216px',
  //     data: {
  //       titleDialog: 'Categoria',
  //       component: DialogCategoryComponent,
  //       data: row,
  //       payload: this.payload
  //     }
  //   });

  //   dialog.afterClosed().subscribe(
  //     category => {
  //       if (category) {
  //         //Salvar edição feita
  //         this.savacategory(category, row.id);
  //       } else {
  //         //Cancelou  edição feita
  //       }

  //     }
  //   );
  // }

  savacategory(dadosCategoria, id = '') {
    var sentData: any;

    sentData = dadosCategoria;
    sentData['client'] = localStorage.getItem('client');
    sentData['user'] = localStorage.getItem('user');
    sentData['jwt'] = localStorage.getItem('token');

    if (id != '') {
      id = '/' + id;
    }

    this.service.resolve('saveCategory' + id, sentData).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data);
        if (id == '/null') {
          var categoria = {};

          categoria['id'] = data.id;
          categoria['titulo'] = dadosCategoria.titulo;
          categoria['sentences'] = 0;

          this.categories.push(categoria);
          this.dataSource = new MatTableDataSource(this.categories);
        } else {
         
          this.categories = this.categories.map((c) => {
            if (c.id == data.id) {
              c.titulo = dadosCategoria.titulo;
            }
            return c;
          });
          
          this.dataSource = new MatTableDataSource(this.categories);
        }
      } else {
        this.notice.showMessageError(data);
      }
    });

  }

  functionCrudDelete(row) {
    const dialog = this.dialog.open(DialogDeleteComponent, {
      width: '450px',
      panelClass: 'rbDefaultIsolatedModal',
      data: {
        titleDialog: 'Excluir categoria',
        component: DialogDeleteComponent,
        data: {
          titulo: 'Esta ação não pode ser desfeita',
          subtitulo: 'Você tem certeza que deseja excluir a categoria ' + row.titulo + '?',
          buttonLeft: { label: 'Cancelar', value: false },
          buttonRigth: { label: 'Sim, excluir', value: true }
        },
        payload: this.payload
      }
  });


    dialog.afterClosed().subscribe(
      deletar => {
        if (deletar == true) {
          //confirmação de exclusão 
          var sentData = {};
          
          sentData['client'] = localStorage.getItem('client');
          sentData['user'] = localStorage.getItem('user');
          sentData['jwt'] = localStorage.getItem('token');
          this.service.resolve('deleteCategory/' + row.id, sentData).then((data) => {
            if (data.success) {
              this.notice.showMessageSuccess(data);
             
              this.categories.splice(this.categories.findIndex((category) => category.id == row.id), 1);
              
              this.dataSource = new MatTableDataSource(this.categories);
            } else {
              this.notice.showMessageError(data);
            }
          });
        } else {
          //Cancelou
        }

      }
    );
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === "") {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      return compare(('' + a[sort.active]).toLowerCase(), ('' + b[sort.active]).toLowerCase(), isAsc);
    });

  }
}

function compare(a: string, b: string, isAsc: boolean) {
  let x, y
  y = a + ''.trim(); // Remove whitespace
  y = y.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  x = b + ''.trim(); // Remove whitespace
  x = x.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return (y.toLowerCase() < x.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
}