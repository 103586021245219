import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NoticeComponent } from './../../../notice/notice.component';
import { DataService } from './../../../../services/data/data.service';
import { FormBuilder, FormGroup, FormControl, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudFieldsService } from "../../../crud-fields/crud-fields.service";
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-dialog-fields',
  templateUrl: './dialog-fields.component.html',
  styleUrls: ['./dialog-fields.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogFieldsComponent implements OnInit {

  fieldsForm: FormGroup;

  hide = true;
  visible = true;
  removable = true;
  addOnBlur = true;
  selectable = true;
  execute = false;
  rubeusUser = false;

  public actions: any = [];
  public actionsChips: any = [];

  intervals: any;
  event: any = [];
  channels: any;
  events: any;
  public dados: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CrudFieldsService>,
    private crudFieldService: CrudFieldsService,
    private service: DataService,
    private notice: NoticeComponent) {
      this.dados = data;
  }

  ngOnInit() {
    if (this.data.rubeusUser.success == true) {
      this.rubeusUser = true;
    }

    this.fieldsForm = this.formBuilder.group({
      totvs_field: [this.data.data != null ? this.data.data.totvs_field : null],
      crm_field: [this.data.data != null ? this.data.data.crm_field : null],
      global_field: [this.data.data != null ? this.data.data.global_field : 0]
    });
  }

  onSubmit() {
    var sentData: any;
    var id: string = '';

    sentData = this.fieldsForm.value;

    sentData['client_id'] = localStorage.getItem('client');
    sentData['user_id'] = localStorage.getItem('user');
    sentData['jwt'] = localStorage.getItem('token');

    if (this.data.data == null) {
      var data = new Date();
      var hora = data.getHours();
      var min = data.getMinutes();
      var seg = data.getSeconds();
      var str_hora = hora + ':' + min + ':' + seg;
      var usuario = localStorage.getItem('username');

      var dataFormatada = ("0" + data.getDate()).substr(-2) + "/" 
      + ("0" + (data.getMonth() + 1)).substr(-2) + "/" + data.getFullYear() + " às " + str_hora + " por " + usuario;

      this.service.resolve('create-fields' , sentData).then((data) => {
        if (data.success) {
          this.notice.showMessageSuccess(data);

          if (id) {
            sentData['id'] = this.data.data.id;
            this.crudFieldService.updateRowData(sentData);
          } else {
            sentData['id'] = data.id;
            sentData['createdOrUpdated'] = dataFormatada;
            this.crudFieldService.addRowData(sentData);
          }
          this.dialogRef.close();
        } else {
          this.notice.showMessageError(data);
        }
      });
    } else {

      sentData['client_id'] = localStorage.getItem('client');
      sentData['user_id'] = localStorage.getItem('user');
      sentData['jwt'] = localStorage.getItem('token');
      id = '/' + this.data.data.id;
      this.service.resolve('update-fields' + id, sentData).then((data) => {
        if (data.success) {
          this.notice.showMessageSuccess(data);
          if (id) {
            sentData['id'] = this.data.data.id;
            this.crudFieldService.updateRowData(sentData);
          } else {
            sentData['id'] = data.id;
            this.crudFieldService.addRowData(sentData);
          }
          this.dialogRef.close();
        } else {
          this.notice.showMessageError(data);
        }
      });

    }
  }

  getIDCreatedAt(id) {
    this.service.resolve('first-date-created' + '/' + this.data.id, this.data).then((data) => {
      return data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
