import { Component, OnInit, Inject, OnDestroy, OnChanges, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.scss']
})
export class HistoricComponent implements OnInit {
  @Input() displayedColumns: string[];


  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  
  msgCrudEmpty: string = 'Sentença ainda não foi alterada!';
  

  columns: any[] = [
    { columnDef: 'name', header: 'Responsável', dataName: row => `${row.name}` },
    { columnDef: 'updated_at', header: 'Data de alteração', dataName: row => this.convertDateBR(row.updated_at)  },
  ];

  public selectedLineMenu: any;
  public selectedLineId: any;

  triggerFilter: boolean;
  displayFilter: boolean;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HistoricComponent>
  ) { }

  ngOnInit( ) {
    this.dataSource = this.data.historicLog
  }

  convertDateBR(date: string) {
    if (date) {
      const day = date.substring(8, 10);
      const month = date.substring(5, 7);
      const year = parseInt(date.substring(0, 4), 10);
      const hour = date.substring(11);
  
      return `${day}/${month}/${year} às ${hour}`;
    }
  }

  activeFilter() {
    if (this.triggerFilter === false) {
      this.triggerFilter = true;
    } else {
      const getAttribute = document.getElementById('triggerFilter');
      getAttribute.classList.remove('scale-in-ver-top');
      getAttribute.classList.add('scale-out-ver-top');
      setTimeout(() => {
        this.triggerFilter = false;
      }, 250);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
}


