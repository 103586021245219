import { Component, OnInit, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import { LoaderService } from './shared/loader/loader.service';
import { DOCUMENT } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';


const scriptPath = environment.intervetion + '/block.js';
declare let blockCrm: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  navLinks: any;
  activeLinkIndex = 0;

  title = 'integracaototvsfront';
  username = '';
  image = '';
  client = '';
  user = '';
 verificarLocalStorage: any;
 public jwtHelperService = new JwtHelperService()

  private jwtDecoded: any;  
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private loaderService: LoaderService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
    ) {
    this.loaderService.setChangesLoaderState(true);
    this.matIconRegistry.addSvgIcon(
      "exit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sign-out.svg")
    );

    this.navLinks = [
      {
        label: 'Sentenças',
        link: 'sentences',
        index: 0
      }, {
        label: 'TBCs',
        link: 'settings',
        index: 1
      }, {
        label: 'Categorias',
        link: 'categories',
      },{
        label: 'Campos',
        link: 'fields',
        index: 2
      }, {
        label: 'Log',
        link: 'log-integration',
        index: 3
      },
    ];
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });

    this.verificarLocalStorage = setInterval(() => {
      // console.log('Verifivando...');
      if (localStorage.getItem('user') && localStorage.getItem('client')) {
        this.username = localStorage.getItem('username');
        this.image = localStorage.getItem('image');
        this.client = localStorage.getItem('client');
        this.user = localStorage.getItem('user');

        clearInterval(this.verificarLocalStorage);
      }
    }, 250);
    this.verifyUser();
    this.scriptInitialization();
  }

  ngAfterViewInit() {
    this.loaderService.setChangesLoaderState(false);
  }

  gotoCategories() {
    this.router.navigateByUrl('categories');
  }

  gotoSettings() {
    this.router.navigateByUrl('settings');
  }

  gotoFields() {
    this.router.navigateByUrl('fields');
  }

  gotoLogIntegrador() {
    this.router.navigateByUrl('log-crud');
  }

  gotoCrm() {
    let crm: string;
    crm = localStorage.getItem('domain');
    window.location.href = crm;
  }

  public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }
    
  verifyUser() {
    const rbAdmToken = localStorage.getItem('tokenAutentic');
    if (rbAdmToken && rbAdmToken !== 'undefined' && rbAdmToken !== 'null') {
        this.jwtDecoded = this.jwtHelperService.decodeToken(rbAdmToken);
      if (this.jwtDecoded ) {
       
      }
    }
  }
  scriptInitialization() {
    const scriptElement = this.loadJsScript(this.renderer, scriptPath);
      scriptElement.onload = () => {
        this.rbxpBlockApp(this.jwtDecoded);
      }
      scriptElement.onerror = () => {
        console.log('Erro');
      }
  }


  rbxpBlockApp(jwtDecoded) {
    var blockInterval = setInterval( function () {
      if (typeof blockCrm  !== 'undefined' ) {
          if(jwtDecoded && jwtDecoded.usuario){
              blockCrm.init({ app: 13, user: jwtDecoded.usuario, crm: jwtDecoded.iss });
              clearInterval(blockInterval);
          }
      }
    }, 50);
  }
}
