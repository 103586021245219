import { Component, ComponentRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger, MatDialog, MatDialogRef, MatSelect } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { EMLINK } from 'constants';
import { Button } from 'protractor';
import { Observable } from 'rxjs';
import { elementAt, map, startWith } from 'rxjs/operators';
import { DataService } from 'src/app/services/data/data.service';
import { DialogSharedComponent } from 'src/app/shared/dialog-shared/dialog-shared.component';
import { DialogSentenceComponent } from '../dialog/template/dialog-sentence/dialog-sentence.component';
import { NoticeComponent } from '../notice/notice.component';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

export interface Systems {
  name: string;
  code: string;
}

export interface Object {
  name: string;
  code: string;
}

export interface Channels {
  app_id: string;
  id: string;
  link: string;
  nome: string;
}

export interface Actions {
  name: string;
  code: string;
  disabled: boolean;
}

export interface Events {
  titulo: string;
  id: string;
  codigo: string;
  origem: string;
  origemNome: string;
}
export interface Categories {
  titulo: string;
  id: string;
  sentences: string;
}

@Component({
  selector: 'app-edit-sentece',
  templateUrl: './edit-sentece.component.html',
  styleUrls: ['./edit-sentece.component.scss'],
  animations: [
    trigger('hasFooter', [
      state('open', style({
        'height': '43px',
        'min-height': '43px',
        'visibility': 'visible',
      })),
      state('closed', style({
        'height': '0px',
        'min-height': '0px',
        'visibility': 'hidden',
      })),
      transition('closed <=> open', [
        animate('0.15s')
      ]),
    ]),
  ]
})


export class EditSenteceComponent implements OnInit, AfterViewInit {

  componentRef: ComponentRef<any>;

  public form: FormGroup;
  public valueInicial: {
    sentence: null,
    colligate: null,
    system: null,
    action: null,
    event: null,
    tbc_settings_id: null,
    channel: null,
    parameters: null,
    interval: null,
    category: null,
    base_legal: null
    in_batch: null
    paused: null
  };

  private sentencePtrn = "^([[R]{1}[B]{1}[A-Z]{2,}\.[WS]{2}\.[0-9\.]{3,}]{0,})$";
  private parametersPtrn = "^([A-Z]*\=[0-9, A-Z, \_\-\#\%\+\(\)]*\;?){0,}$";

  visible = true;
  removable = true;
  addOnBlur = true;
  selectable = true;
  execute = false;

  public actions: any = [];
  public actionsChips: any = [];

  intervals: any;
  channels: Channels[];
  events: Events[];
  categories: Categories[];
  event: any = [];
  category: any = [];
  legalBase: any = [];
  LGPDAtiva: false;
  legalBases = [];
  tbc_options: any = [];

  isShown: boolean = false; // hidden by default

  systems: Systems[] = [
    { name: 'S (Educacional)', code: 'S' },
    { name: 'G (Global)', code: 'G' },
    { name: 'F (Financeiro)', code: 'F' },
  ];

  actionsSelect: Actions[] = [
    { name: 'Unidade', code: 'unit', disabled: false },
    { name: 'Curso', code: 'course', disabled: false },
    { name: 'Processo Seletivo', code: 'selectiveProcess', disabled: false },
    { name: 'Oferta', code: 'offer', disabled: false },
    { name: 'Local de oferta', code: 'offerPlace', disabled: false },
    { name: 'Contato', code: 'contact', disabled: false },
    { name: 'Evento', code: 'event', disabled: false },
  ];

  filteredEvents: Observable<Events[]>;
  public valueEventsOptions: Events[] = new Array();

  filteredCategories: Observable<Categories[]>;
  public valueCategoriesOptions: Categories[] = new Array();
  
  filteredlegaBases: Observable<any[]>;

  @ViewChild('acts', { static: true }) acts: MatSelect;
  @ViewChild(MatAutocompleteTrigger, { static: false }) _auto: MatAutocompleteTrigger;

  @Input() sentece: any;

  public hasChange: boolean = false;
  public hasDeletedChips: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private service: DataService,
    private notice: NoticeComponent,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.sentece = route.snapshot.data.sentece;

    this.channels = this.sentece.channels;
    this.events = this.sentece.events;
    this.intervals = this.sentece.intervals;
    this.tbc_options = this.sentece.tbc_options;
    this.categories = this.sentece.categories;
    this.legalBases = this.sentece.legalBases.data;
    this.LGPDAtiva = this.sentece.legalBases.LGPDAtiva;
  }


  ngOnInit() {
    this.execute = false;

    if (this.sentece.data) {
      this.actions = this.sentece.data.action;
      this.event = this.sentece.data.event;
      this.category = this.sentece.data.categorie_id;
      this.legalBase = this.sentece.data.base_legal ? this.sentece.data.base_legal : null;

      if(this.sentece.data.cache){
        this.sentece.data.cache = this.minutesToDays(this.sentece.data.cache);
      } else{
        this.sentece.data.cache = {
          days: null,
          hours: null
        }
      }
    }


    this.form = this.formBuilder.group({
      sentence: [this.sentece.data != null ? this.sentece.data.sentence : null, [Validators.required /*, Validators.pattern(this.sentencePtrn) */]],
      colligate: [this.sentece.data != null ? this.sentece.data.colligate : null, Validators.required],
      system: [this.sentece.data != null ? this.sentece.data.system.toLocaleUpperCase() : null , Validators.required],
      action: [null, Validators.required],
      event: [null],
      category: [this.sentece.data != null ? this.sentece.data.category : null],
      first_execution: [this.sentece.data != null ? this.sentece.data.first_execution : null, this.validahora],
      interval_execution: [this.sentece.data != null ? this.sentece.data.interval_execution : null],
      base_legal: [null, this.LGPDAtiva ? Validators.required : null],
      description: [this.sentece.data != null ? this.sentece.data.description : null],
      cacheDays: [this.sentece.data != null ? this.sentece.data.cache.days : null],
      cacheHours: [this.sentece.data != null ? this.sentece.data.cache.hours : null],
      in_batch: [this.sentece.data != null ? this.sentece.data.in_batch == 1 : null],
      paused: [this.sentece.data != null ? this.sentece.data.paused == 1 : null],
      tbc_settings_id: [this.sentece.data != null ? this.sentece.data.tbc_settings_id : null],
      channel: [this.sentece.data != null ? this.sentece.data.origin : null, Validators.required],
      parameters: [this.sentece.data != null ? this.sentece.data.parameters : null /*, Validators.pattern(this.parametersPtrn)*/],
      interval: [this.sentece.data != null ? parseInt(this.sentece.data.interval) : null, Validators.required],
    });
    this.controlSelectValue();
    this.buildFilteredCategories();
    this.buildFilteredSubscriptions();
    this.controlDisabledEventsField(0);
    this.filteredlegaBases = this.form.controls.base_legal.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.base),
        map(base => base ? this._filter(base, this.legalBases) : this.legalBases.slice())
      );

    this.controlAutocompleteValue();

    setTimeout(() => {
      this.valueInicial = this.form.value;
      this.valueInicial.action = this.actions;
      this.valueInicial.event = this.event;
      this.valueInicial.category = this.category;
      if(this.legalBase) {
        this.valueInicial.base_legal = this.legalBase;
      }
    }, 0)
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.buildChangeControl();
    }, 10);
  }

  buildChangeControl() {
    this.form.valueChanges.subscribe(()=>this.setHasChange(this.alteracao));
  }

  setHasChange(value) {
    this.hasChange = value;
  }

  setHasDeletedChips(value) {
    this.hasDeletedChips = value;
  }

   minutesToDays(minutos) {
      var day = Math.floor(minutos / 1440);
      var restMin = minutos % 1440;
      var hour = Math.floor(restMin / 60);
      
      return {
          days: day,
          hours: hour
      };
  }

  buildFilteredSubscriptions() {
    if (this.form.get('event')) {
      this.filteredEvents = this.form.get('event').valueChanges
        .pipe(
          startWith(''),
          map(option => this.filterEventOptions(option))
      );
    }

  }

  filterEventOptions(value): Events[] {
    if(value || value==='') {
      return this.events.filter(option => 
          this.getFiltredChannelEvents(option) ? (
            value && typeof value === 'string' ? option.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(
            value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) !== -1 : -1
          ) : null
      );
    } 
  }

  setInitialValueOptionsEvents() {
    if(!this.form.get('event').value || this.form.get('event').value === null 
    || this.form.get('event').value===''
    || (typeof this.form.get('event').value === 'object' 
      && !this.form.get('event').value.id 
      && !this.form.get('event').value.length)) {
        this.form.get('event').setValue('');
    }
  }

  controlDisabledEventsField(clearEventField) {
    if(clearEventField) {
      this.form.get('event').setValue('');
    }
    if(this.form.get('channel').value) {
      this.form.get('event').enable();
    } else {
      this.form.get('event').disable();
    }
  }

  getFiltredChannelEvents(option) {
    return option.origem === this.form.get('channel').value ? true : false;
  }


  buildFilteredCategories() {
    if (this.form.get('category')) {
      this.filteredCategories = this.form.get('category').valueChanges
        .pipe(
          startWith(''),
          map(option => option ? this.filterCategoriesOptions(option) : this.categories.slice())
      );
    }
  }

  filterCategoriesOptions(value): Categories[] {
    if(value) {
      return this.categories.filter(option => 
        value && typeof value === 'string' ? option.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(
        value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) !== -1 : -1
      );
    } 
  }

  get alteracao() {


    return this.isNotEquivalent(this.form.value, this.valueInicial);
  }

  isNotEquivalent(a, b) {

    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    if (aProps.length != bProps.length) {
        return true;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        if (typeof a[propName] === 'object') {
          if (JSON.stringify(a[propName]) !== JSON.stringify(b[propName])) {
            return true;
          }
        } else if (a[propName] !== b[propName]) {
            return true;
        }
    }

    return false;
}

  get contactExist() {
    var contactExists = this.form.value.action.indexOf("contact");

    if (contactExists == -1) {
      contactExists = false;
      this.form.removeControl("base_legal");
    } else {
      contactExists = true;
      this.form.addControl("base_legal", new FormControl(null, Validators.required));
    }
    return contactExists;
  }

  displayFn(value) {
    return value ? value.titulo ? value.titulo : value.base : null;
  }

  private _filter(titulo: string, elemento) {
    const filterValue = titulo.toLowerCase();

    return elemento.filter(element => element.titulo && element.titulo.toLowerCase().indexOf(filterValue) === 0 ||
      element.base && element.base.toLowerCase().indexOf(filterValue) === 0);
  }

  validahora(control: FormControl): { [s: string]: boolean } {

    if (!control.value) {
      return null
    }
    let tempo, hora, min, seg;

    tempo = '' + control.value + '';
    hora = tempo.split(':')[0];
    min = tempo.split(':')[1];
    seg = '00';//tempo.split(':')[2];
    //console.log(hora, min);
    if (hora == 0) {
      if (min == 0) {
        if (seg == 0) {
          return { error: true };
        }
      }
    }

    if (seg == 0) {
      if (min == 0) {
        if (hora == 0) {
          return { error: true };
        }
      }
    }

    if (
      !(hora >= 0 && hora < 24 && min >= 0 && min < 60 && seg >= 0 && seg < 60)
    ) {
      return { error: true };
    }

    return null;
  }

  remove(index): void {
    this.actionsChips.splice(index, 1);
    let rowAll: any
    this.actions = [];

    for (rowAll of this.actionsSelect) {  
      for (let rowSelected of this.actionsChips) {
        if (rowSelected.code === rowAll.code) {
          this.actions.push(rowAll.code)
          this.disbaleAction(rowSelected.code);
        }
      }
    }
    this.setHasDeletedChips(true);
    this.controlSelectValue();
  }

  controlSelectValue() {
    let rowAll: any
    this.actionsChips = [];
    this.actionsSelect.map((elm) => {

      elm.disabled = false;

    })

    for (rowAll of this.actionsSelect) {
      for (let rowSelected of this.actions) {
        if (rowSelected === rowAll.code) {
          this.actionsChips.push(rowAll)
        }
        this.disbaleAction(rowSelected);
      }
    }
  }

  disbaleAction(Action) {
    switch (Action) {
  
      case "contact":
        this.actionsSelect.map((elm) => {
          if (["offer", "unit", "course", "offerplace", "selectiveProcess", "offerPlace"].includes(elm.code)) {
            elm.disabled = true;
          }
        })
        break;
      case "event":
        this.actionsSelect.map((elm) => {
          if (["offer", "course", "offerplace", "selectiveProcess", "offerPlace", "unit"].includes(elm.code)) {
            elm.disabled = true;
          }
        })
        break;
      case "unit":
        this.actionsSelect.map((elm) => {
          if (["offer", "course", "offerplace", "selectiveProcess","event", "contact"].includes(elm.code)) {
            elm.disabled = true;
          }
        })
        break;
      case "course":
        this.actionsSelect.map((elm) => {
          if (["offerplace","event", "contact", "unit", "offerPlace"].includes(elm.code)) {
            elm.disabled = true;
          }
        })
        break;
      case "offer":
        this.actionsSelect.map((elm) => {
          if (["offerplace","event", "contact", "unit", "offerPlace"].includes(elm.code)) {
            elm.disabled = true;
          }
        })
        break;
      case "selectiveProcess":
      this.actionsSelect.map((elm) => {
        if (["offerplace","event", "contact", "unit", "offerPlace"].includes(elm.code)) {
          elm.disabled = true;
        }
      })
      break;
      case "offerPlace":
        this.actionsSelect.map((elm) => {
          if (["event", "contact", "selectiveProcess", "offer", "course"].includes(elm.code)) {
            elm.disabled = true;
          }
        })
        break;
      default:
        break;
    }
  }

  controlAutocompleteValue() {
    let rowAll: any

    for (rowAll of this.events) {
      if (this.event === rowAll.id) {
        this.event = rowAll;
      }
    }

    for (rowAll of this.categories) {
      if (this.category === rowAll.id) {
        this.category = rowAll;
      }
    }

    for (rowAll of this.legalBases) {
      if (this.legalBase == rowAll.id) {
        this.legalBase = rowAll;
      }
    }
  }

  back() {
    if (this.alteracao) {
      const dialog = this.dialog.open(DialogSharedComponent, {

        width: '450px',
        data: {
          title: 'Voltar',
          menssage: 'Há algumas alterações que não foram salvas, deseja realmente voltar?',
          buttonRight: { label: 'Voltar e ignorar alterações', value: 'sair' },
          buttonLeft: { label: 'Continuar editando', value: 'continuar' }

        }
      });
      dialog.afterClosed().subscribe(
        close => {
          if (close == 'continuar') {
          } else {
            this.router.navigate(['sentences']);
          }

        }
      );
    } else {
      this.router.navigate(['sentences']);
    }
  }

  onReset(): void {

    if (this.sentece.data) {

      const dialog = this.dialog.open(DialogSharedComponent, {

        width: '380px',
        data: {
          title: 'Redefinir',
          menssage: 'Alterações que não tenham sido salvas serão descartadas, deseja realmente redefinir?',
          buttonRight: { label: 'Cancelar', value: 'cancelar' },
          buttonLeft: { label: 'Redefinir alterações', value: 'redefinir' }

        }
      });
      dialog.afterClosed().subscribe(
        close => {
          if (close == 'cancelar') {
          } else {
            this.setHasDeletedChips(false);
            this.resetForm();
            this.controlSelectValue();
          }

        }
      );
    } else {
      this.back();
    }
  }

  resetForm() {
    for (const key in this.valueInicial) {
      if(this.form.contains(key)) {
        this.form.get(key).setValue(this.valueInicial[key]);
      }
    }

    this.hasChange = false;
  }

  executeSentence() {
    this.execute = true;
    this.onSubmit();
  }

  onSubmit() {
    var sentData: any;
    var id: string = '';

    sentData = this.form.value;
    sentData['client'] = localStorage.getItem('client');
    sentData['user'] = localStorage.getItem('user');
    sentData['jwt'] = localStorage.getItem('token');
    sentData['execute'] = this.execute;
    sentData['cache'] = this.daysToMinutes(sentData.cacheDays, sentData.cacheHours);

    if (this.sentece.data != null) {
      id = '/' + this.sentece.data.id;
    } 

    this.service.resolve('saveSentence' + id, sentData).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data);
        this.router.navigate(['sentences']);

        for (const channel of this.channels) {
          if (channel.id == sentData.channel) {
            sentData['channelName'] = channel.nome;
          }
        }

        if (sentData.event != null) {
          for (const event of this.events) {
            if (event.id == sentData.event.id) {
              sentData['eventTitle'] = event.titulo;
            }
          }
        }

        for (const interval of this.intervals) {
          if (interval.id == sentData.interval) {
            sentData['intervalTitle'] = interval.description;
          }
        }
        for (const tbc of this.tbc_options) {
          if (tbc.id == sentData.tbc_settings_id) {
            sentData['tbc'] = tbc.discription
          }


        }

        if (id) {
          sentData['id'] = this.sentece.data.id;
          sentData['execution'] = data.executed;
        } else {
          sentData['id'] = data.id;
          sentData['execution'] = data.executed;
        }
        // this.dialogRef.close(this.sentece.data);
      } else {
        this.notice.showMessageError(data);
      }


    });
  }

  daysToMinutes(days, hours) {
    const minutesDay = 1440;
    const minutesHour = 60;
    const daysCache = days ? days: 0;
    const hoursCache = hours ? hours: 0;
    const minutes = (daysCache * minutesDay) + (hoursCache * minutesHour);
    return minutes;
  }

  buildTitles(array: any[], options, id, titleReference, title) {
    for (const item of array) {
      for (const opt of options) {
        if (opt.id == item[id]) {
          item[title] = opt[titleReference];
        }
      }
    }
    return array;
  }

}