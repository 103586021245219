<div *ngIf="this.noFields == true && this.loading == 4" fxLayout="column" fxLayoutAlign="space-around center">
    <mat-card style="height: 530px">
        <div>
            <p><img src="../../assets/icons/integracaototvs.svg"></p>
    
            <p class="title">Vincules os campos do TOTVS com os do <b>Rubeus</b>!</p>
    
            <p class="description"></p>
            <p style="margin-bottom: 15px">
                <button color="primary" mat-raised-button (click)="openCreateFieldDialog()" class="btn">Vincular Campos</button>
            </p>
        </div>
    </mat-card>
</div>

<div *ngIf="this.hasFields == true && this.loading == 4" fxLayout="row" fxLayoutAlign="center">     
        <mat-card>
            <app-crud-fields
                [(receivedFieldsData)] = "dataSource"
                [(columnsFields)] = "columns"
                (clickedFieldCreate) = "openCreateFieldDialog()"
                (clickedFieldDelete) = "getDataDialogDelete($event)"
                (clickedFieldEdit) = "getDataDialogEdit($event)" 
                >
            </app-crud-fields>
        </mat-card>
</div>
