import { Component, OnInit, OnDestroy, ViewChild, HostListener, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { CrudTbcService } from './crud-tbc.service';
import { MatSort, MatDialog } from '@angular/material';
import { timeout } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { DialogDeleteComponent } from '../dialog/template/dialog-delete/dialog-delete.component';



@Component({
  selector: 'app-crud-tbc',
  templateUrl: './crud-tbc.component.html',
  styleUrls: ['./crud-tbc.component.scss']
})
export class CrudTbcComponent implements OnInit, OnChanges, OnDestroy {
  @Input() displayedColumns: string[];
  @Input() receivedTbcData: any;
  @Input() tableTitle: string;
  @Input() columnsTbc: any[] = [];

  @Output() clickedTbcEdit = new EventEmitter();
  @Output() clickedLink = new EventEmitter();
  @Output() clickedTbcDelete = new EventEmitter();
  @Output() clickedTbcCreate = new EventEmitter();
  @Output() clickedTbcExecute = new EventEmitter();
  @Output() pageTbcEvent = new EventEmitter<PageEvent>();

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  selection = new SelectionModel<any>(true, []);
  mSort: any;
  decimal: any;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
      this.mSort = sort;
  }

  pageIndex = 0;
  pageSize = 25;
  length: any;

  public selectedLineMenu: any;
  public selectedLineId: any;

  triggerFilter: boolean;
  displayFilter: boolean;


  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private CrudTbcService: CrudTbcService,
    private dialog: MatDialog
  ) {
    iconRegistry.addSvgIcon(
      'filter',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/filter.svg'));
    iconRegistry.addSvgIcon(
      'link',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/link.svg'));
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/copy.svg'));

    // Status filter
    this.triggerFilter = false;
    this.displayFilter = CrudTbcService.statusFilter;
  }

  ngOnInit() {
    
    var decimalPipe = new DecimalPipe(navigator.language);

    this.paginator._intl.itemsPerPageLabel = 'Itens por página: ';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} de ${decimalPipe.transform(length)}`;
    };
  }

  ngAfterViewInit() {
     this.dataSource.sort = this.mSort;
  }


  ngOnChanges() {
    if ((this.columnsTbc !== undefined || this.columnsTbc !== null) && this.receivedTbcData !== undefined){
      this.dataSource = new MatTableDataSource(this.receivedTbcData);
      this.displayedColumns = this.columnsTbc.map(x => x.columnDef);
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.pageSize = this.pageSize;
      this.dataSource.paginator.pageIndex = this.pageIndex;
      // this.dataSource.paginator.length = this.metaCount;
      this.CrudTbcService.rowsCrud(this.dataSource, this.receivedTbcData, this.table, this.paginator);
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  functionCrudEdit(param) {
    this.clickedTbcEdit.emit(param);
  }

  functionTbcCreate() {
    this.clickedTbcCreate.emit();
  }

  // functionCrudDelete(param) {

  //   this.clickedTbcDelete.emit(param);
  // }

  functionCrudDelete(row) {
    const dialog = this.dialog.open(DialogDeleteComponent, {
      width: '450px',
      panelClass: 'rbDefaultIsolatedModal',
      data: {
        titleDialog: 'Excluir TBC',
        component: DialogDeleteComponent,
        data: {
          titulo: 'Esta ação não pode ser desfeita',
          subtitulo: 'Tem certeza que deseja excluir o TBC ' + row.discription + '?',
          buttonLeft: { label: 'Cancelar', value: false },
          buttonRigth: { label: 'Sim, excluir', value: true }
        },
        // payload: this.payload
      }
    });

    dialog.afterClosed().subscribe(
      deletar => {
        if (deletar == true) {
          this.clickedTbcDelete.emit(row.id);
        }
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: { id: number; }): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.CrudTbcService.statusFilterDefault.next(false);
  }

  saveSelectedLineMenu(row) {
    this.selectedLineMenu = row;
    this.selectedLineId = row.id;
  }

}
