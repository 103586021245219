import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NoticeComponent } from '../notice/notice.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogTbcComponent } from '../dialog/template/dialog-tbc/dialog-tbc.component';
import { MatDialog } from '@angular/material';
//importacação do crutbc, mais especificamente do crud-service
import { CrudTbcService } from "../crud-tbc/crud-tbc.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {


  public noSentences: boolean = false;
  public hasSentences: boolean = false;
  public loading = 0;
  public tbc: any;
  hide = true;
  formulario: FormGroup;
  private payload: object;
  private sentData: object;
  public channels: any;
  public events: any;
  public intervals: any[] = new Array();
  public dataSource: any[] = new Array();

  columns: any[] = [
    { columnDef: 'id', header: 'ID', dataName: row => `${row.id}` },
    { columnDef: 'discription', header: 'Descrição', dataName: row => `${row.discription}` },
    { columnDef: 'tbc', header: 'Link do TBC', dataName: row => `${row.tbc}`},
    { columnDef: 'totvs_user', header: 'Usuário', dataName: row => `${row.totvs_user}` },
    { columnDef: 'last_alter', header: 'Última alteração', dataName: row => `${row.lastAlter ? row.lastAlter: '--'}` },    
    { columnDef: 'options', dataName: row => `${row.id}`}
    ];
    pageIndex = 1;
    pageSize = 25;


  constructor(
    private formBuilder: FormBuilder,
    private service: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private notice: NoticeComponent,
    private dialog: MatDialog,
    //importação da classe do crudtbc
    private crudService: CrudTbcService
  ) { }

  ngOnInit() {
    
    const state = localStorage.getItem('state');

    if (state != 'ok') {
      this.router.navigate(['']);
    }

    this.payload = {
      client : localStorage.getItem('client'),
      user : localStorage.getItem('user'),
      jwt : localStorage.getItem('token')
    };

     this.getTotvsData();
    
  }

  onSubmit() {
    this.sentData = this.formulario.value;
    this.sentData['client'] = localStorage.getItem('client');
    this.sentData['user'] = localStorage.getItem('user');
    this.sentData['jwt'] = localStorage.getItem('token');

    this.service.resolve('updateTotvsData', this.sentData).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data);
      } else {
        this.notice.showMessageError(data);
      }
    });
  }

  getTotvsData() {
    let response: any;
    this.service.resolve('getTotvsData', this.payload).then((data) => {
      this.dataSource = data;
      if (data.length != 0) {
        this.dataSource = data;
        this.hasSentences = true;
        this.loading += 1;
      } else {
        this.dataSource = data;
        this.noSentences = true;
        this.loading += 1;
      }
      this.getChannel();
      this.getInterval();
      this.getEvent();
      this.getTbc();
    });
  }

  getChannel() {
    this.service.resolve('channels', this.payload).then((obj) => {
      this.channels = obj;
      this.dataSource = this.buildTitles(this.dataSource, this.channels.data, 'origin', 'nome', 'channelName');
      this.loading += 1;
    });
  }

  getEvent() {
    this.service.resolve('events', this.payload).then((obj) => {
      this.events = obj;
      this.dataSource = this.buildTitles(this.dataSource, this.events.data, 'event', 'titulo', 'eventTitle');
      this.loading += 1;
    });
  }

  getInterval() {
    this.service.resolve('intervals', this.payload).then((obj) => {
      this.intervals = obj;
      let interval = obj.data;
      this.dataSource = this.buildTitles(this.dataSource, interval, 'interval', 'description', 'intervalTitle');
      this.loading += 1;
    });
  }

  getTbc(){
    this.service.resolve('getTotvsData', this.payload).then((obj) => {
      this.tbc = obj;
      this.dataSource = this.buildTitles(this.dataSource, this.tbc, 'tbc_settings_id', 'discription', 'tbc');
    });
  }

  buildTitles(array: any[], options, id, titleReference, title) {
    for (const item of array) {
      for (const opt of options) {
        if (opt.id == item[id]) {
          item[title] = opt[titleReference];
        }
      }
    }
    return array;
  }

  openCreateTbcDialog(tbcdata=null) {
    this.dialog.open(DialogTbcComponent, {
      width: '790px',
      minHeight: '466px',
      panelClass: 'rbDefaultIsolatedModal',
      data: {
        titleDialog: tbcdata == null ? 'Cadastrar TBC' : 'Editar TBC',
        component: DialogTbcComponent,
        data: tbcdata,
        payload: this.payload
      }
    });
  }

  // openEditTbcDialog(tbcdata) {
  //   this.dialog.open(DialogComponent, {
  //     width: '750px',
  //     minHeight: '500px',
  //     data: { // Todos os dados (TBC e SendData)
  //       titleDialog: 'Cadastrar TBC',
  //       component: DialogTbcComponent,
  //       data: tbcdata, //Dados apenas do TBC
  //       payload: this.payload
  //     }
  //   });
  // }

  getTbcData(id: any) {
    let response: any;
    this.service.resolve(
      'editTbc/' + id, this.payload).then((obj: any) => {
        if(obj && obj.data) {
          response = obj.data;
          this.openCreateTbcDialog(response);
        }
    });
  }

  deleteTbc(id: number) {
    this.service.resolve('deleteTbc/' + id, this.payload).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data);
        this.spliceCrud(id);
      } else {
        this.notice.showMessageError(data);
      }
    });
  }

  spliceCrud(id) {

    for (const [index, item] of this.dataSource.entries()) {
      if (item.id == id) {
        this.dataSource.splice(index, 1);
        this.crudService.deleteRowData(id);
      }
    }
  }

}
