<form action="" [formGroup]="logcrm">
<div mat-dialog-title class="ps-header-dialog" fxLayoutAlign="start center">
    <div fxFlex="" fxLayoutAlign="start center" fxLayout="row" class="ps-dialog-title">
        <div fxFlex="">{{dados.titleDialog}}</div>
        <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
    </div>
</div>

    <mat-dialog-content class="container rb-padding-16x20">
        <p style="font-size: 13px; font-weight: inherit; margin-top:0px;">
            Visualização
        </p>
        <div fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex0" appearance="fill">
                <input [readonly]="true" matInput formControlName="sentence" id="sentence" type="text" class="validate"/>
                <mat-label>SQL</mat-label>
            </mat-form-field>
        
        </div>

        <div *ngIf="this.data.data.response === null" fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex1" appearance="fill">
                <input [readonly]="true" matInput formControlName="uri" id="uri" type="text" class="validate"/>                
                <mat-label>Método</mat-label>
            </mat-form-field>

        </div>
        
        <div fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex0" appearance="fill">
                <input [readonly]="true" matInput formControlName="created_at" id="created_at" type="text" class="validate"/>
                <mat-label>Data</mat-label>
            </mat-form-field>

        </div>

        <div *ngIf="this.data.data.request != null" fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex1" appearance="fill">
                <input [readonly]="true" matInput formControlName="request" id="request" type="text" class="validate"/>
                <mat-label>Request</mat-label>
            </mat-form-field>
        </div>

        <div *ngIf="this.data.data.response != null" fxLayout="row" fxLayoutGap="27px"> 
            <button mat-raised-button (click)="copyMessage(this.data.data.request)" value="Copiar para área de tranferência" >Copiar
                <mat-icon matPrefix>content_copy</mat-icon>
            </button>
        </div>

        <br>

        <div *ngIf="this.data.data.response != null" fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex1" appearance="fill">
                <input [readonly]="true" matInput formControlName="response" id="response" type="text" class="validate"/>
                <mat-label>Response</mat-label>
            </mat-form-field>
        </div>

        <div *ngIf="this.data.data.response != null" > 
            <button mat-raised-button (click)="copyMessage(this.data.data.response)" value="Copiar para área de tranferência" >Copiar
                <mat-icon matPrefix>content_copy</mat-icon>
             </button>
        </div>
        
        <div *ngIf="!this.data.data.response" fxLayout="row" fxFill>
            <div fxFlex fxLayoutAlign="center">
                <button mat-raised-button (click)="downloadLog()">Baixar log</button>
                <br>
                <br>
            </div>
        </div> 
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
        <button (click)="onNoClick()" mat-raised-button>Sair</button>
    </mat-dialog-actions>
</form>
     
