<div [ngClass]="fullScreen? 'modal-sql-sentence': '' ">
    <div mat-dialog-title class="ps-header-dialog" fxLayoutAlign="start center">
        <div fxFlex="" fxLayoutAlign="start center" fxLayout="row" class="ps-dialog-title">
            <div fxFlex="">{{dados.titleDialog}}</div>
            <mat-icon class="ps-dialog-fullscreen"
                matTooltip="{{fullScreen ? 'Restaurar tamanho': 'Tela cheia'}}"
                matTooltipClass="tooltip-sql  mat-elevation-z8"
                (click)="onFullScree()"> {{ fullScreen ? 'fullscreen_exit': 'fullscreen'}}</mat-icon>
            <mat-icon class="ps-dialog-close"
                matTooltip="Fechar"
                matTooltipClass="tooltip-sql  mat-elevation-z8"
                (click)="onNoClick()">close</mat-icon>
        </div>
    </div>

    <mat-dialog-content class="container rb-padding-16x20" [ngClass]="fullScreen? 'full-screen' : ''">
        <p class="fonte-label">
            SQL Query
            <mat-icon class="icon-info last"
            matTooltip="Caso o registro seja retornado novamente pela execução da sentença, dentro do período de sete (7) dias, o evento relacionado ao mesmo não será gerado na Plataforma Rubeus."
            matTooltipClass="tooltip-sql  mat-elevation-z8" matTooltipPosition="after">info</mat-icon>
        </p>
          <div>
              <ngx-monaco-editor
                class="auto-size my-code-editor"
                [options]="editorOptions"
                [(ngModel)]="dados.sql"
                (ngModelChange)="getParamsSentence()"></ngx-monaco-editor>
          </div>
          <p class="fonte-label mat-form-field">
            Variáveis:
            <mat-icon class="icon-info last"
              customToolTip [contentTemplate]="variaveis"
              matTooltipClass="tooltip-sql  mat-elevation-z8" matTooltipPosition="after">info</mat-icon>
          </p>
          <div>
            <mat-form-field *ngFor="let param of paramsSentence"  appearance="fill" class="mat-form-field">
              <mat-label>{{param.key}}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="param.value"
                [(ngModel)]="param.value">
            </mat-form-field>
          </div>
          <div *ngIf="resultSentence">
             <ngx-monaco-editor
                  class="my-code-editor auto-size"
                  [options]="editorResult"
                  [(ngModel)]="resultSentence"
                  (onInit)="indentMonaco($event)"></ngx-monaco-editor>
          </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
        <button (click)="onNoClick()" mat-stroked-button>Fechar</button>
        <button
            mat-stroked-button
            [disabled]="oldSql == dados.sql ? 'false': 'true'"
            (click)="executeSentece()"
            color="primary"
        >Executar</button>
        <button
          mat-raised-button
          [disabled]="oldSql == dados.sql ? 'true': 'false' "
          (click)="updateSqlSentence()"
          color="primary"
        >Salvar</button>

    </mat-dialog-actions>
</div>

<ng-template #variaveis>
  <div class="">
      <span>
        Utilize<b> :NOME </b>para adicionar  variáveis à sua sentença,
      </span>
  </div>
</ng-template>
