import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-first-popup',
  templateUrl: './dialog-first-popup.component.html',
  styleUrls: ['./dialog-first-popup.component.scss']
})
export class DialogFirstPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
