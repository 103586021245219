import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-shared',
  templateUrl: './dialog-shared.component.html',
  styleUrls: ['./dialog-shared.component.scss']
})
export class DialogSharedComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,) {
    
  }

  ngOnInit() {
  }

}
