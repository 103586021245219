import { Component, OnInit, Input } from '@angular/core';
import { DialogComponent } from '../../dialog.component';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog-custom-fields',
  templateUrl: './dialog-custom-fields.component.html',
  styleUrls: ['./dialog-custom-fields.component.scss']
})

export class DialogCustomFieldsComponent implements OnInit {

  constructor(
    private dateComponent: DialogComponent,
    public dialogRef: MatDialogRef<DialogCustomFieldsComponent>
    ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
