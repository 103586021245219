import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  public statusFilterDefault = new BehaviorSubject(false);
  currentStatusFilter = this.statusFilterDefault.asObservable();
  statusFilter: boolean;

  public statusInfoCrudDefault = new BehaviorSubject(false);
  currentStatusInfoCrud = this.statusInfoCrudDefault.asObservable();
  statusInfoCrud: boolean;
  textInfoCrud: string;

  dataSource: any;
  receivedData: any;
  table: any;
  paginator: any;
  sort: any;
  selection: any;

  constructor(private route: ActivatedRoute, private router: Router) { }

  /**
   * @param parameter e
   */
  changeStatusFilter(parameter: boolean, column?: Array<string>, dados?: object) {
    this.currentStatusFilter.subscribe(d => this.statusFilter = d);
    this.statusFilterDefault.next(parameter);

    if (parameter === true && typeof column !== 'undefined' && typeof dados !== 'undefined') {
      return this.buildFilter(column, dados);
    }
  }

  public rowsCrud(dataSource, receivedData, table, paginator, sort) {
    this.dataSource = dataSource;
    this.receivedData = receivedData;
    this.table = table;
    this.paginator = paginator;
    this.sort = sort;
    this.refreshTable();
  }
  public addRowData(rowObj) {
    if (Array.isArray(rowObj) && typeof this.dataSource.data !== 'undefined') {
      const array = this.dataSource.data;
      Array.prototype.push.apply(array, rowObj);
      this.table.renderRows();
      this.refreshTable();
      if (this.paginator) {
        this.paginator.length = array.length;
      }
      this.dataSource.sort = this.sort;
    } else if (Array.isArray(rowObj) && typeof this.dataSource.data === 'undefined') {
      const array = this.dataSource;
      Array.prototype.push.apply(array, rowObj);
      this.table.renderRows();
      this.refreshTable();
      if (this.paginator) {
        this.paginator.length = array.length;
      }
      this.dataSource.sort = this.sort;
    } else if (typeof this.dataSource.data === 'undefined') {
      const array = this.dataSource;
      array.push(rowObj);
      this.table.renderRows();
      this.refreshTable();
      if (this.paginator) {
        this.paginator.length = array.length;
      }
      this.dataSource.sort = this.sort;
    } else {
      const array = this.dataSource.data;
      array.push(rowObj);
      this.table.renderRows();
      this.refreshTable();
      if (this.paginator) {
        this.paginator.length = array.length;
      }
      this.dataSource.sort = this.sort;
    }
  }
  public updateRowData(rowObj) {
    this.dataSource.data = this.dataSource.data.filter((value, key) => {
      if (value.id === rowObj.id) {
        // tslint:disable-next-line: forin
        for (const index in rowObj) {
          value[index] = rowObj[index];
        }
      }
      return true;
    });
    this.table.renderRows();
    this.dataSource.sort = this.sort;
  }
  public deleteRowData(rowObj) {
    if (typeof this.dataSource.data === 'undefined') {
      const array = this.receivedData;
      const result = this.arrayRemove(array, Number(rowObj));
      this.receivedData = result;
      this.table.renderRows();
      if (this.paginator) {
        this.paginator.length = result.length;
      }
      this.dataSource.sort = this.sort;
    } else {
      const array = this.dataSource.data;
      const result = this.arrayRemove(array, Number(rowObj));
      this.dataSource.data = result;
      this.table.renderRows();
      if (this.paginator) {
        this.paginator.length = result.length;
      }
      this.dataSource.sort = this.sort;
    }
  }
  public rowChange(obj) {
    return obj;
  }
  private refreshTable() {
    if (this.paginator) {
      this.paginator._changePageSize(this.paginator.pageSize);
    }
  }
  private arrayRemove(arr, value) {
    return arr.filter((ele) => {
      return ele.id !== value;
    });
  }
  // public setRowChecked(params) {
  //   this.valueChecked.next(params);
  // }

  // public getRowCheck(): Observable<any> {
  //   return this.valueChecked.asObservable();
  // }

  public getSelection(selection) {
    this.selection = selection;
    return selection;
  }
  public isAllSelected(dataSource) {
    const numSelected = this.selection.selected.length;
    const numRows = dataSource.length;
    return numSelected === numRows;
  }
  public getRowChecked($event, dataRow, array, dataSource) {
    if (this.isAllSelected(dataSource.fakeData)) {
      array = dataSource.fakeData;
    } else if ($event.checked) {
      array.push(dataRow);
    } else if ($event.checked === false && typeof dataRow !== 'undefined') {
      array = array.filter(item => item !== dataRow);
    } else {
      array = [];
    }
    // setTimeout(() => {
    //   this.setRowChecked(array);
    // });
    return array;
  }

  private buildFilter(column, dados) {
    // const reduced = dados.reduce(function (filtered, colum) {
    //   if (column) {
    //     const someNewValue = { coluna: column, newProperty: 'Foo' };
    //     filtered.push(someNewValue);
    //   }
    //   return filtered;
    // }, []);

    const filtered = [];

    // tslint:disable-next-line: forin
    for (const key in column) {
      // console.log(column[key]);
      const p = column[key];
      // tslint:disable-next-line: forin
      for (const keyy in dados) {
        const op = dados[keyy][p];
        // console.log(p + '--', dados[keyy][p]);
      }
      // tslint:disable-next-line: no-shadowed-variable
      // const aa = dados.filter((s => () => !s.has(column[key]) && s.add(column[key]))(new Set));
      // console.log(aa);
    }
    const pp = column[0];
    // tslint:disable-next-line: no-shadowed-variable
    // const aa = dados.filter((s => () => !s.has(pp) && s.add(pp))(new Set));
    // console.log(aa);
  }
}
