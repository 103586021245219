<div class="ps-header-dialog" fxLayout="row">
  <div fxFlex="80" fxLayoutAlign="start center">
    <span class="ps-dialog-title">{{dados.titleDialog}}</span>
  </div>
  <div fxFlex="20" fxLayoutAlign="end center">
    <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
  </div>
</div>
<div style="padding-top: 20px;">
  <mat-dialog-content style="position: relative;">
    <ng-template #target></ng-template>
    
  </mat-dialog-content>
</div>
