<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
        <div style="height: 530px" *ngIf="this.noSentences == true" fxLayout="column" fxLayoutAlign="center center">
            <p><img src="../../assets/icons/integracaototvs.svg"></p>
    
            <p class="title">Sem registros na base de dados!</p>
        </div>
        
        <div *ngIf="this.hasSentences == true && this.loadingColumns >= 1" >
            <div>
                <app-crud-log
                [(receivedLogData)] = "dataSource"
                [(columnsLog)] = "columns"
                [(filters)] = "filters"
                (clickedTbcVisualize) = "getLogData($event)" >
                </app-crud-log>
            </div>
        </div>
        
    </mat-card>
</div>

