
<div mat-dialog-title class="ps-header-dialog" fxLayoutAlign="start center">
    <div fxFlex="" fxLayoutAlign="start center" fxLayout="row" class="ps-dialog-title">
        <div fxFlex="">{{dados.titleDialog}}</div>
        <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="container rb-padding-16x20">
    <p fxFlex fxLayoutAlign="center center">Tem certeza que deseja excluir a sentença:<strong> {{name}}</strong>?</p>
    
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
    <a mat-stroked-button (click)="closeDialog()" style="color: black !important;">Cancelar</a>
    <button style="margin-right: -3px;" fxLayoutAlign type="button" class="btn btn-danger" (click)="deleteSentence()">Sim, excluir</button>
</mat-dialog-actions>