<div fxLayout="row" class="rbNoticeContainer">
  <div fxFlex="">
    <p class="noticeTitle">{{titleNotice}}</p>
    <ul class="noticeList" *ngIf="arrayNotice">
      <li *ngFor="let message of arrayNotice">
        <span *ngIf="getTypeOf(message) === 'object' && message.length">
          <span *ngFor="let item of message; last as isLast">{{item}}<span *ngIf="!isLast">, </span> </span>
        </span>
        <span *ngIf="getTypeOf(message) === 'object'">
          <span><b>{{message.campo}}:</b> {{message.erro}}</span>
        </span>
        <span *ngIf="getTypeOf(message) === 'string'">
          <span>{{message}}</span>
        </span>
      </li>
    </ul>
    <p class="noticeText">{{dataNotice}}</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-button (click)="close()" >
        <span class="noticeTitle">Fechar</span>
      </button>
  </div>
</div>