<div fxLayout="column" class="ps-row" fxLayout.gt-xs="row" class="ps-crud-header">
    <div fxFlex fxLayoutAlign="start center">
      <button 
        mat-raised-button 
        (click)="functionTbcCreate()">Cadastrar TBC
      </button>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" >
  <mat-form-field fxLayoutAlign class="ps-crud-input-pesq">
    <mat-icon matPrefix>search</mat-icon>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Pesquisar">
  </mat-form-field>

    <mat-paginator fxLayoutAlign="end center" [pageSizeOptions]="[25, 50, 100]">
  </mat-paginator>
</div>

  
  <table mat-table #table matSort [dataSource]="dataSource" class="ps-crud-table">
  
    <ng-container *ngFor="let column of columnsTbc" [matColumnDef]="column.columnDef">
  
      <div
        *ngIf="column.columnDef !== 'check' && column.columnDef !== 'buttonEdit' && column.columnDef !== 'buttonLink' && column.columnDef !== 'buttonDelete' && column.columnDef !== 'options'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> {{column.header}} </th>
        <td td mat-cell *matCellDef="let row">
          <span [title]="column.dataName(row)"> {{ (column.dataName(row).length>80) ? (column.dataName(row) | slice:0:20)+'...':(column.dataName(row)) }} </span>
        </td>
      </div>  
  
  
      <div *ngIf="column.columnDef === 'buttonEdit'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td td mat-cell *matCellDef="let row" style="text-align: center; width: 10px;">
          <button
            mat-button
            class="ps-crud-button-tr"
            id="column.dataName(row)"
            (click)="functionCrudEdit(column.dataName(row))">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </div>
  
  
      <div *ngIf="column.columnDef === 'buttonDelete'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td td mat-cell *matCellDef="let row" style="text-align: center; width: 10px;">
          <button
            mat-button
            class="ps-crud-button-tr"
            id="column.dataName(row)"
            (click)="functionCrudDelete(column.dataName(row))">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </div>
  
      <div *ngIf="column.columnDef === 'options'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td td mat-cell *matCellDef="let row" style="text-align: center; width: 30px;">
          <button mat-button
            [ngClass]="{isVisible: selectedLineId === row.id, 'rb-crud-drag-cursor': ''}"
            class="ps-crud-button-tr rbCrudMenuButton rb-float-button"
            [matMenuTriggerFor] = "optionMenu"
            [matMenuTriggerData] = "row"
            (menuOpened)="saveSelectedLineMenu(row)"
            (menuClosed)="selectedLineId=null"
            id="column.dataName(row)">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #optionMenu="matMenu" xPosition="before" yPosition="below">
            <button mat-menu-item (click)="functionCrudEdit(column.dataName( row ))"><mat-icon>edit</mat-icon>Editar</button>
            <button mat-menu-item (click)="functionCrudDelete(row)"><mat-icon>delete</mat-icon>Excluir</button>
          </mat-menu>
        </td>
      </div>
  
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{isVisible: selectedLineId === row.id}"></tr>
  </table>
  
  
  