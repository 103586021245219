<form action="" [formGroup]="fieldsForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="ps-header-dialog" fxLayoutAlign="start center">
        <div fxFlex="" fxLayoutAlign="start center" fxLayout="row" class="ps-dialog-title">
            <div fxFlex="">{{dados.titleDialog}}</div>
            <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
        </div>
    </div>
    <mat-dialog-content class="container rb-padding-16x20">
        <p style="font-size: 13px; font-weight: inherit; margin-top:0px;">
            Correspondência de campos
        </p>
        <div fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex1" appearance="fill">
                <mat-label>Campo TOTVS</mat-label>
                <input matInput formControlName="totvs_field" id="campo_totvs" type="text" class="validate"/>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex1" appearance="fill" >
                <input matInput formControlName="crm_field" id="campo_crm" type="text" class="validate"/>                
                <mat-label>Campo correspondente no Rubeus</mat-label>
            </mat-form-field>
        </div>

        <div *ngIf="rubeusUser == true" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px"> 
            <mat-checkbox  
                    fxFlex="50%"
                    color="primary"
                    formControlName="global_field">
                    Tornar o campo global
            </mat-checkbox >
            <div fxLayoutAlign>
                <mat-icon class="icone" matTooltip="Permite que a correspondência entre os campos seja utilizada para todos os clientes. &#13;
                    Função disponível somente para usuários Rubeus." 
                    matTooltipClass="tooltip-avaliacao mat-elevation-z8"  style="color: #7F7F7F;">info</mat-icon>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
        <a mat-stroked-button (click)="onNoClick()" style="color: black !important;">Cancelar</a>
        <button type="submit" mat-raised-button>Salvar</button>
    </mat-dialog-actions>
</form>