import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NoticeComponent } from 'src/app/components/notice/notice.component';
import { DataService } from 'src/app/services/data/data.service';
import { FieldsComponent } from "../../../fields/fields.component";
import { CrudFieldsService } from "../../../crud-fields/crud-fields.service";


@Component({
  selector: 'app-dialog-delete-fields',
  templateUrl: './dialog-delete-fields.component.html',
  styleUrls: ['./dialog-delete-fields.component.scss']
})
export class DialogDeleteFieldsComponent implements OnInit {

  private payload: object;
  public dados: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogDeleteFieldsComponent>,
    private service: DataService,
    private router: Router,
    // private fieldsComponente: FieldsComponent,
    private fieldService: CrudFieldsService,
    private notice: NoticeComponent
  ) { 
    this.dados = data;
  }
  public totvs_field = this.data.data.totvs_field;
  public crm_field = this.data.data.crm_field;
  public id = this.data.data.id;
  

  ngOnInit() {
    this.totvs_field = this.totvs_field == null ? '-' : this.totvs_field;
    this.crm_field = this.crm_field == null ? '-' : this.crm_field;


    console.log(this.id);

    const state = localStorage.getItem('state');

    if (state != 'ok') {
      this.router.navigate(['']);
    }

    this.payload = {
      client: localStorage.getItem('client'),
      user: localStorage.getItem('user'),
      jwt: localStorage.getItem('token')
    };

  }

  deleteField() {
    this.service.resolve('field-delete/' + this.id, this.payload).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data);
        console.log(this.id)
        //parei aqui
        this.spliceCrud(this.id);
        this.dialogRef.close();
      } else {
        this.notice.showMessageError(data);
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  spliceCrud(id) {
    for (const [index, item] of this.data.dataSource.entries()) {
      if (item.id == id) {
        this.data.dataSource.splice(index, 1);
        this.fieldService.deleteRowData(id);
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
