import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';


@Component({
  selector: 'select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  /** list of elements */
  @Input() elements: any[] = [];
  @Input() elementsSelected: any[] = [];
  @Input() placeholder: string = 'Pesquisar..'
  @Output() onChangeSelectSearch = new EventEmitter();
  

  /** total number of available entries */
  public allElementsSize = this.elements.length;
  
  /** control for the selected element for multi-selection */
  public elementMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public elementMultiFilterCtrl: FormControl = new FormControl();

  /** list of elements filtered by search keyword */
  public filteredElementsMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  /** local copy of filtered elements to help set the toggle all checkbox state */
  protected filteredElementsCache: any[] = [];

  /** flags to set the toggle all checkbox state */
  isIndeterminate = false;
  isChecked = false;

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  constructor() { }

  ngOnInit() {
    // set initial selection
    //this.elementMultiCtrl.setValue(this.elementsSelected ? this.elementsSelected : []);

    // load the initial element list
    this.filteredElementsMulti.next(this.elements.slice());

    // listen for search field value changes
    this.elementMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterElementsMulti();
        this.setToggleAllCheckboxState();
      });

      // listen for multi select field value changes
    this.elementMultiCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.setToggleAllCheckboxState();
      this.onChangeSelectSearch.emit(this.elementMultiCtrl);
    });
    
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredElementsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.elementMultiCtrl.patchValue(val);
        } else {
          this.elementMultiCtrl.patchValue([]);
        }
      });
  }

  /**
   * Sets the initial value after the filteredElements are loaded initially
   */
  protected setInitialValue() {
    this.filteredElementsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredElements are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterElementsMulti() {
    if (!this.elements) {
      return;
    }
    // get the search keyword
    let search = this.elementMultiFilterCtrl.value;
    if (!search) {
      this.filteredElementsCache = this.elements.slice();
      this.filteredElementsMulti.next(this.filteredElementsCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the elements
    this.filteredElementsCache = this.elements.filter(element => element.label.toLowerCase().indexOf(search) > -1);
    this.filteredElementsMulti.next(this.filteredElementsCache);
  }

  protected setToggleAllCheckboxState() {
    let filteredLength = 0;
    if (this.elementMultiCtrl && this.elementMultiCtrl.value) {
      this.filteredElementsCache.forEach(el => {
        if (this.elementMultiCtrl.value.indexOf(el) > -1) {
          filteredLength++;
        }
      });
      this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredElementsCache.length;
      this.isChecked = filteredLength > 0 && filteredLength === this.filteredElementsCache.length;
    }
  }
}
