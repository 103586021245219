// import { NoticeComponent } from '../view/component/notice/notice.component';

export class Request {
    public url: string;
    public data: any;
    // protected noticeComponent: NoticeComponent;
    
      constructor(
      ) {}
    
      successAction(data) {
        console.log('==> data ', data);
      }
    
      errorAction(error) {
        if (error.error) {
          console.log('error', error.error);
          // this.noticeComponent.showMessageError(error.error);
        } else {
          console.log('erro ->', error);
          // this.noticeComponent.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
        }
      }
    
      finalAction() {
        // console.log('==> final');
      }
    }
    