import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './components/auth/auth.component';
import { HomeComponent } from './components/home/home.component';
import { SentencesComponent } from './components/sentences/sentences.component';
import { SettingsComponent } from './components/settings/settings.component';
import { RbNoticeBarComponent } from './components/notice/notice.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogFirstPopupComponent } from './components/dialog/template/dialog-first-popup/dialog-first-popup.component';
import { DialogSentenceComponent } from './components/dialog/template/dialog-sentence/dialog-sentence.component';
import { DialogCustomFieldsComponent } from './components/dialog/template/dialog-custom-fields/dialog-custom-fields.component';
import { SaveDialogComponent } from './components/dialog/save-dialog/save-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteGuardService } from './guards/route-guard.service';
import { 
  MatButtonModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatGridListModule,
  MatTableModule,
  MatPaginatorModule,
  MatIconModule,
  MatSnackBarModule,
  MatMenuModule,
  MatToolbarModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatOptionModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatDividerModule,
  MatTabsModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatSortModule,
  MatPaginatorIntl
} from '@angular/material';
import { NotauthorizedComponent } from './components/notauthorized/notauthorized.component';
import { DialogTbcComponent } from './components/dialog/template/dialog-tbc/dialog-tbc.component';
import { CrudTbcComponent } from './components/crud-tbc/crud-tbc.component';
import { HistoricComponent } from './components/historic/historic.component';
import { getDutchPaginatorIntl } from 'rubeus-components';
import { CrudLogComponent } from './components/crud-log/crud-log.component';
import { LogIntegrationComponent } from './components/log-integration/log-integration.component';
import { MAT_DATE_LOCALE, SatDatepicker, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { DialogLogCrudComponent } from './components/dialog/template/dialog-log-crud/dialog-log-crud.component';
import { DialogDeleteSentenceComponent } from './components/dialog/template/dialog-delete-sentence/dialog-delete-sentence.component';
import { EditSenteceComponent } from './components/edit-sentece/edit-sentece.component';
import { DialogSharedComponent } from './shared/dialog-shared/dialog-shared.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CrudCategoryComponent } from './components/crud-category/crud-category.component';
import { DialogCategoryComponent } from './components/dialog/template/dialog-category/dialog-category.component';
import { DialogDeleteComponent } from './components/dialog/template/dialog-delete/dialog-delete.component';
import { DialogChangeColumnsOrderComponent } from './components/dialog/template/dialog-change-columns-order/dialog-change-columns-order.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CrudModule } from './shared/crud/crud.module';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CrudFieldsComponent } from './components/crud-fields/crud-fields.component';
import { FieldsComponent } from './components/fields/fields.component';
import { DialogFieldsComponent } from './components/dialog/template/dialog-fields/dialog-fields.component';
import { DialogDeleteFieldsComponent } from './components/dialog/template/dialog-delete-fields/dialog-delete-fields.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { SelectSearchModule } from './shared/select-search/select-search.module';
import { DialogSqlSentenceComponent } from './components/dialog/template/dialog-sql-sentence/dialog-sql-sentence.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { CustomToolTipComponent } from './shared/custom-tool-tip/custom-tool-tip.component';
import { DirectivesModule } from './shared/directives/directives.module';

registerLocaleData(localePt);

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    SentencesComponent,
    RbNoticeBarComponent,
    DialogComponent,
    DialogCustomFieldsComponent,
    SaveDialogComponent,
    SettingsComponent,
    DialogFirstPopupComponent,
    DialogSentenceComponent,
    NotauthorizedComponent,
    DialogTbcComponent,
    CrudTbcComponent,
    HistoricComponent,
    CrudLogComponent,
    LogIntegrationComponent,
    DialogDeleteSentenceComponent,
    DialogLogCrudComponent,
    EditSenteceComponent,
    DialogSharedComponent,
    CrudCategoryComponent,
    DialogCategoryComponent,
    DialogDeleteComponent,
    DialogChangeColumnsOrderComponent,
    CrudFieldsComponent,
    FieldsComponent,
    DialogFieldsComponent,
    DialogDeleteFieldsComponent,
    LoaderComponent,
    DialogSqlSentenceComponent,
    CustomToolTipComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatSnackBarModule,
    MatMenuModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatChipsModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    MatTabsModule,
    CrudModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(maskConfig),
    MatSortModule,
    DragDropModule,
    SelectSearchModule,
    FormsModule,
    MonacoEditorModule.forRoot(),
    DirectivesModule
  ],
  providers: [
    SentencesComponent,
    RouteGuardService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    RbNoticeBarComponent,
    DialogComponent,
    DialogCustomFieldsComponent,
    DialogSentenceComponent,
    DialogFirstPopupComponent,
    DialogTbcComponent,
    HistoricComponent,
    DialogDeleteSentenceComponent,
    DialogLogCrudComponent,
    DialogSharedComponent,
    DialogCategoryComponent,
    DialogDeleteComponent,
    DialogChangeColumnsOrderComponent,
    CrudFieldsComponent,
    FieldsComponent,
    DialogFieldsComponent,
    DialogDeleteFieldsComponent,    
    DialogSqlSentenceComponent,
    CustomToolTipComponent
    ],
    
})
export class AppModule { }
