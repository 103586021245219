import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})


export class CategoryResolver implements Resolve<any> {

  public id;
  public objetoCompleto = {
    data: null,
    channels: null,
    intervals: null,
    events: null,
    tbc_options: null,
    categories: null,
    legalBases: null
  };

  public payload = {
    client: localStorage.getItem('client'),
    user: localStorage.getItem('user'),
    jwt: localStorage.getItem('token')
  };

  constructor(private service: DataService) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.service.resolve('get-Categories', this.payload).then((obj) => {
      if (obj && obj.data) {
        this.objetoCompleto = obj.data;
        return this.objetoCompleto;
      }
    });
  }
}
