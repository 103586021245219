<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
    <p style="font-size: 13px; font-weight: inherit; margin-top:0px;">
        Configurações TOTVS
    </p>
    <form action="" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex1" appearance="fill">
                <mat-label>Sentença SQL</mat-label>
                <input matInput formControlName="sentence" id="sentence" type="text" class="validate" />
                <mat-hint>Exemplo: RBRT.WS.001</mat-hint>
                <mat-error *ngIf="form.controls.sentence.invalid">Sentença inválida</mat-error>
            </mat-form-field>

            <mat-form-field class="flex1" appearance="fill">
                <mat-select formControlName="system" >
                    <mat-option *ngFor="let system of systems" [value]="system.code">
                        {{ system.name }}
                    </mat-option>
                </mat-select>
                <mat-label for="system">Sistema</mat-label>
                <mat-hint>Defina se é consultado do sistema educacional, financeiro, etc</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex1" appearance="fill">
                <input matInput formControlName="colligate" id="colligate" type="number" class="validate">
                <mat-label>Código da coligada</mat-label>
                <mat-hint>Somente números</mat-hint>
                <mat-error *ngIf="form.controls.colligate.invalid">Coligada inválida</mat-error>
            </mat-form-field>

            <mat-form-field class="flex1" appearance="fill">
                <input matInput formControlName="parameters" id="parameters" type="text" class="validate"/>
                <mat-label>Parâmetros TOTVS</mat-label>
                <mat-hint>Ex: PARAM1=VALOR;PARAM2=VALOR;</mat-hint>
                <mat-error *ngIf="form.controls.parameters.invalid">Parametro(s) inválido(s)</mat-error>
            </mat-form-field>
            
            <mat-form-field class="flex0" appearance="fill">
                <mat-select formControlName="tbc_settings_id" [required]="true" >
                    <mat-option *ngFor="let tbc_options of tbc_options" [value]="tbc_options.id" >
                        {{ tbc_options.discription }}
                    </mat-option>
                </mat-select>
                <mat-label for="system">Vincular TBC</mat-label>
                <mat-hint>TBC a ser vinculado</mat-hint>
            </mat-form-field>
        </div>

        <p style="font-size: 13px; font-weight: inherit">
            Configurações Rubeus
        </p>

        <div fxLayout="row" fxLayoutGap="16px">
            <mat-form-field fxFlex="30" appearance="fill"> 
                <mat-label>Canal Rubeus integrado ao TOTVS</mat-label>
                <mat-select formControlName="channel">
                    <mat-option *ngFor="let channel of channels" [value]="channel.id">
                        {{ channel.nome }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="45" appearance="fill"> 
                <input matInput formControlName="event" type="text" [(ngModel)]="event" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let event of filteredEvents | async" [value]="event">
                            {{ event.titulo }}
                        </mat-option>
                    </mat-autocomplete>
                <mat-label>Tipo de evento integrado (Opcional)</mat-label>
            </mat-form-field>

            <mat-form-field fxFlex="25" appearance="fill">
                <mat-select formControlName="interval">
                    <mat-option *ngFor="let interval of intervals" [value]="interval.id">
                        {{interval.description}}
                    </mat-option>
                </mat-select>
                <mat-label>Intervalo entre execuções</mat-label>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="16px"> 
            <mat-form-field class="flex0" appearance="fill">
                <mat-select 
                    #acts id="acts"
                    formControlName="action"
                    [(ngModel)]="actions"
                    (selectionChange)="controlSelectValue()"
                    multiple>
                    <mat-option *ngFor="let action of actionsSelect" [value]="action.code">
                        {{ action.name }}
                    </mat-option>
                </mat-select>
                <mat-label>Ações do Rubeus</mat-label>
                <mat-hint>Selecione ao menos uma ação</mat-hint>
            </mat-form-field>

            <div class="flex2">
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let action of actionsChips; let actionIndex = index"
                        [selectable]="selectable"
                        [removable]="removable"
                        (removed)="remove(actionIndex)">
                        {{action.name}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <br>
        </div>


        <mat-dialog-actions style="position: absolute;bottom: 0px;width: 100%;left: 0;">
            <div fxLayout="row" class="ps-divider-container ps-divider-margin">
                <div fxFlex="100">
                    <mat-divider></mat-divider>
                </div>
            </div>
            <div fxLayout="row" fxFill>
                <div fxFlex fxLayoutAlign="end center" class="ps-dialog-content-actions">
                    <a mat-stroked-button (click)="onNoClick()" style="color: black !important;">Cancelar</a>
                    <button mat-raised-button >Salvar</button>
                    <button mat-raised-button (click)="executeSentence()">Salvar e executar</button>
                </div>
            </div>
        </mat-dialog-actions>
    </form>
</div>