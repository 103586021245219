<form [formGroup]="tbc" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="ps-header-dialog" fxLayoutAlign="start center">
        <div fxFlex="" fxLayoutAlign="start center" fxLayout="row" class="ps-dialog-title">
            <div fxFlex="">{{dados.titleDialog}}</div>
            <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
        </div>
    </div>
    <mat-dialog-content class="container rb-padding-16x20">
        <p style="font-size: 13px; font-weight: inherit; margin-top:0px;">
            Configurações TOTVS Business Connect
        </p>
        <div fxLayout="row" fxLayoutGap="27px">
            <mat-form-field class="flex1" appearance="fill">
                <mat-label>Descrição do TBC</mat-label>
                <input matInput formControlName="discription" type="text" class="validate" />
                <mat-hint>A descrição não pode ter mais de 191 caracteres</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="27px">
            <mat-form-field fxFlex="50%" appearance="fill">
                <input matInput formControlName="totvs_user" id="totvs_user" type="text" class="validate" />
                <mat-label>Usuário do TOTVS</mat-label>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50%" appearance="fill" class="alinha-div">
                <a *ngIf="data.data && !ocultarButton" class="tirarunderline" fxLayoutAlign style="color: black;"
                    mat-stroked-button (click)="ocultarButton = true">Alterar senha</a>

                <mat-form-field *ngIf="ocultarButton || !dados.data?.id" fxFlex appearance="fill">
                    <input matInput [required]="ocultarButton" formControlName="totvs_pass" id="totvs_pass" [type]="hide ? 'password' : 'text'"
                        class="validate" />
                    <mat-label>Senha Totvs</mat-label>
                    <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="27px">
            <mat-form-field fxFlex="48.5%" appearance="fill">
                <input matInput formControlName="tbc" id="tbc" type="text" class="validate" />
                <mat-label>Link do TBC</mat-label>
                <mat-hint>Insira o link do TBC</mat-hint>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50%" appearance="fill" class="alinha-div">


                <a mat-stroked-button style="color: black !important;" (click)="verifiyTbc()">Validar</a>
                <div class="tirarunderline" fxLayoutAlign style="color: gray;padding-inline-start: 8px;"
                    *ngIf="this.loading == 1">
                    <div fxLayoutAlign>
                        <span>Validando...</span>
                    </div>
                </div>
                <div class="tirarunderline" fxLayoutAlign style="color: #62DF9B;padding-inline-start: 8px;"
                    *ngIf="this.tbcSuccess == true && this.loading == 0">
                    <mat-icon style="margin-top: -2px;">check</mat-icon>
                    <div fxLayoutAlign>
                        <span> Conectou com sucesso! </span>
                    </div>
                </div>
                <a class="tirarunderline" fxLayoutAlign style="color: red;padding-inline-start: 8px"
                    *ngIf="this.tbcSuccess == false && this.loading == 0">
                    <mat-icon style="margin-top: -2px;">close</mat-icon>
                    <div fxLayoutAlign>
                        <span>{{this.tbcSuccessMsg}}</span>
                    </div>
                </a>
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="27px" *ngIf="userRubeus">
            <mat-slide-toggle fxFlex="70%" color="primary" formControlName="license_not_required">
                Utilizar métodos que não requerem licença
            </mat-slide-toggle>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
        <a mat-stroked-button style="color: black !important;" (click)="onNoClick()">Cancelar</a>
        <button type="submit" mat-raised-button [disabled]="!tbc.valid || !alteracao">Salvar</button>
    </mat-dialog-actions>
</form>