import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup,FormBuilder } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NoticeComponent } from '../notice/notice.component';
import { MatDialog } from '@angular/material';
import { CrudFieldsService } from "../crud-fields/crud-fields.service";
import { DialogFieldsComponent } from "../dialog/template/dialog-fields/dialog-fields.component";
import { DialogComponent } from '../dialog/dialog.component';
import { DialogDeleteFieldsComponent } from '../dialog/template/dialog-delete-fields/dialog-delete-fields.component';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {

  public noFields: boolean = false;
  public hasFields: boolean = false;
  public loading = 0;
  public fields: any;
  hide = true;
  formulario: FormGroup;
  private payload: object;
  private sentData: object;
  public channels: any;
  public events: any;
  public intervals: any[] = new Array();
  public dataSource: any[] = new Array();
  public rubeusUser: boolean = false;

  @Output() isUserRubeus = new EventEmitter();


  columns: any[] = [
    { columnDef: 'id', header: 'ID', dataName: row => `${row.id}` },
    { columnDef: 'totvs_field', header: 'Nome do Campo no TOTVS', dataName: row => `${row.totvs_field}` },
    { columnDef: 'crm_field', header: 'Correspondente no Rubeus', dataName: row => `${row.crm_field}` },
    { columnDef: 'created_at', header: 'Ultima alteração', dataName: row => `${row.createdOrUpdated}` },
    { columnDef: 'buttonEdit', dataName: row => `${row.id}` },
    { columnDef: 'buttonDelete', dataName: row => `${row.id}` }
  ];
  pageIndex = 1;
  pageSize = 25;

  constructor(
    private formBuilder: FormBuilder,
    private service: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private notice: NoticeComponent,
    private dialog: MatDialog,
    //importação da classe do crudtbc
    private crudFieldService: CrudFieldsService,
  ) { }

  ngOnInit() {
    const state = localStorage.getItem('state');

    if (state != 'ok') {
      this.router.navigate(['']);
    }

    this.payload = {
      client: localStorage.getItem('client'),
      user: localStorage.getItem('user'),
      jwt: localStorage.getItem('token')
    };

    this.getFieldsData();
    this.isRubeusUser()
  }


  getFieldsData() {
    let response: any;
    this.service.resolve('getFieldsData', this.payload).then((data) => {
      this.dataSource = data;
      if (data.length != 0) {
        this.dataSource = data;
        this.hasFields = true;
        this.loading += 1;
      } else {
        this.dataSource = data;
        this.noFields = true;
        this.loading += 1;
      }
      this.getChannel();
      this.getInterval();
      this.getEvent();
      this.getFields();

    });
  }

  getChannel() {
    this.service.resolve('channels', this.payload).then((obj) => {
      this.channels = obj;
      this.dataSource = this.buildTitles(this.dataSource, this.channels.data, 'origin', 'nome', 'channelName');
      this.loading += 1;
    });
  }

  getEvent() {
    this.service.resolve('events', this.payload).then((obj) => {
      this.events = obj;
      this.dataSource = this.buildTitles(this.dataSource, this.events.data, 'event', 'titulo', 'eventTitle');
      this.loading += 1;
    });
  }

  getInterval() {
    this.service.resolve('intervals', this.payload).then((obj) => {
      this.intervals = obj;
      let interval = obj.data;
      this.dataSource = this.buildTitles(this.dataSource, interval, 'interval', 'description', 'intervalTitle');
      this.loading += 1;
    });
  }

  //ver  esse parametro 'id' se está correto
  getFields(){
    this.service.resolve('getFieldsData', this.payload).then((obj) => {
      this.fields = obj;
      console.log(this.fields);
      this.dataSource = this.buildTitles(this.dataSource, this.fields, 'fields_id', 'totvs_field', 'createdOrUpdated');
    });
  }

  isRubeusUser(){
    this.service.resolve('rubeus-user-check', this.payload).then((obj) => {
      this.rubeusUser = obj;
      console.log(this.rubeusUser);
    });
  }

  functionIsUserRubeus(param) {
    this.isUserRubeus.emit(param);
  }

  buildTitles(array: any[], options, id, titleReference, title) {

    for (const item of array) {
      for (const opt of options) {
        if (opt.id == item[id]) {
          item[title] = opt[titleReference];
        }
      }
    }
    return array;
  }

  openCreateFieldDialog(data = null) {
    this.dialog.open(DialogFieldsComponent, {
      width: '375px',
      panelClass: 'rbDefaultIsolatedModal',
      data: {
        titleDialog: 'Campo',
        component: DialogFieldsComponent,
        data: data,
        payload: this.payload,
        rubeusUser: this.rubeusUser
      }
    });
  }

  getDataDialogDelete(id: any) {
    var id: any;
    this.service.resolve(
      'field-data-delete/' + id, this.payload).then((obj: any) => {
        if(obj) {
          id = obj;
          this.openDialogDelete(id);
        }
    });
  };

  openDialogDelete(response) {
    this.dialog.open(DialogDeleteFieldsComponent, {
      minWidth: '463px',
      minHeight: '300px',
      panelClass: 'rbDefaultIsolatedModal',
      data: { // Todos os dados 
        titleDialog: 'Excluir correspondência',
        component: DialogDeleteFieldsComponent,
        data: response, //Dados apenas field
        payload: this.payload,
        dataSource: this.dataSource
      }
    });
  };

  getDataDialogEdit(id: any) {
    var id: any;
    this.service.resolve(
      'field-data-delete/' + id, this.payload).then((obj: any) => {
        if(obj) {
          id = obj;
          this.openCreateFieldDialog(id);
        }
    });
  };

  spliceCrud(id) {

    for (const [index, item] of this.dataSource.entries()) {
      if (item.id == id) {
        this.dataSource.splice(index, 1);
        this.crudFieldService.deleteRowData(id);
      }
    }
  }



}
