<div class="container">
    <div class="card-mainn mat-elevation-z8" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 0px 18px;">
            <div fxLayout="row" fxLayoutAlign="start center" class="button-voltar">
                <button mat-icon-button (click)="back()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <span>Voltar</span>
            </div>
            <div *ngIf="sentece.data">
                <span class="ultima-alteracao" *ngIf="sentece.data.ultima_alteracao">
                    {{'Última alteração ' + sentece.data.ultima_alteracao}}
                </span>
            </div>
        </div>
        <form action="" [formGroup]="form">
            <mat-divider></mat-divider>
            <p class="label-config">
                Configurações TOTVS
            </p>
            <div fxLayout="row" fxLayoutGap="27px">
                <mat-form-field fxFlex="33" appearance="fill">
                    <mat-label>Sentença SQL</mat-label>
                    <input matInput formControlName="sentence" required id="sentence" type="text" class="validate" />
                    <mat-hint>Exemplo: RBRT.WS.001</mat-hint>
                    <mat-error *ngIf="form.controls.sentence.invalid">Sentença inválida</mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="33" appearance="fill">
                    <mat-select formControlName="system" required>
                        <mat-option *ngFor="let system of systems" [value]="system.code">
                            {{ system.name }}
                        </mat-option>
                    </mat-select>
                    <mat-label for="system">Sistema</mat-label>
                    <mat-hint>Defina o código do sistema TOTVS em que a consulta é cadastrada.</mat-hint>
                    <mat-icon class="icon-info"
                        matTooltip="Defina se é consultado do sistema educacional, financeiro, etc."
                        matTooltipClass="tooltip-avaliacao mat-elevation-z8" matTooltipPosition="after">help</mat-icon>
                </mat-form-field>



                <mat-form-field fxFlex="33" appearance="fill">
                    <input matInput formControlName="colligate" required id="colligate" type="number" class="validate">
                    <mat-label>Código da coligada</mat-label>
                    <mat-hint>Somente números</mat-hint>
                    <mat-error *ngIf="form.controls.colligate.invalid">Coligada inválida</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="27px">
                <mat-form-field fxFlex="66" appearance="fill">
                    <input matInput formControlName="parameters" id="parameters" type="text" class="validate" />
                    <mat-label>Parâmetros TOTVS</mat-label>
                    <mat-hint>Ex: PARAM1=VALOR;PARAM2=VALOR;</mat-hint>
                    <mat-error *ngIf="form.controls.parameters.invalid">Parametro(s) inválido(s)</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex appearance="fill">
                    <mat-select formControlName="tbc_settings_id" [required]="true">
                        <mat-option *ngFor="let tbc_options of tbc_options" [value]="tbc_options.id">
                            {{ tbc_options.discription }}
                        </mat-option>
                    </mat-select>
                    <mat-label for="system">Vincular TBC</mat-label>
                    <mat-hint>Escolha o TBC você deseja vincular.</mat-hint>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <p class="label-config">
                Configurações Rubeus
            </p>
            <div fxLayout="row" fxLayoutGap="27px">
                <mat-form-field fxFlex="33" appearance="fill">
                    <mat-label>Canal Rubeus integrado ao TOTVS</mat-label>
                    <mat-select formControlName="channel" required (selectionChange)="controlDisabledEventsField(1)">
                        <mat-option *ngFor="let channel of channels" [value]="channel.id">
                            {{ channel.nome }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="33" appearance="fill">
                    <mat-select #acts id="acts" formControlName="action" required [(ngModel)]="actions"
                        (selectionChange)="controlSelectValue()" multiple>
                        <mat-option matTooltip="Ação não compatível com as que estão selecionadas!"
                            matTooltipClass="tooltip-disabled-fields mat-elevation-z8"
                            [matTooltipDisabled]="!action.disabled" *ngFor="let action of actionsSelect"
                            [value]="action.code" [disabled]="action.disabled">
                            {{ action.name }}
                        </mat-option>
                    </mat-select>
                    <mat-label>Ações do Rubeus</mat-label>
                    <mat-error>Selecione ao menos uma ação.</mat-error>

                </mat-form-field>

                <div fxFlex="33">
                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let action of actionsChips; let actionIndex = index" [selectable]="selectable"
                            [removable]="removable" (removed)="remove(actionIndex)">
                            {{action.name}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>

            <div fxLayout="column">
                <div fxFlex fxLayoutGap="27px">
                    <mat-form-field fxFlex="33" appearance="fill" *ngIf="LGPDAtiva && contactExist">
                        <mat-select formControlName="base_legal" [required]="LGPDAtiva && contactExist"
                            [(ngModel)]="legalBase">
                            <mat-option *ngFor="let legalBase of filteredlegaBases | async" [value]="legalBase">
                                {{ legalBase.base }}
                            </mat-option>
                        </mat-select>
                        <mat-label>Base legal </mat-label>
                        <mat-error>Selecione uma base legal para ação de contato. Esse campo é obrigatório.</mat-error>
                        <mat-icon class="icon-info"
                            matTooltip="Ações com contatos requerem uma base legal para adequação a LGPD"
                            matTooltipClass="tooltip-avaliacao mat-elevation-z8" matTooltipPosition="after">help
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field fxFlex="33" appearance="fill"
                        *ngIf="actions.includes('event')">
                        <input matInput formControlName="event" type="text" [(ngModel)]="event" [matAutocomplete]="auto"
                            (click)="setInitialValueOptionsEvents()">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let event of filteredEvents | async" [value]="event">
                                {{ event.titulo }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-label>Tipo de evento integrado</mat-label>
                    </mat-form-field>

                    <div fxFlex="33" *ngIf="!actions.includes('event')"></div>
                    <div fxFlex="33" *ngIf="!actions.includes('contact')"></div>
                    <div fxFlex="33"></div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <p class="label-config">
                Configurações da sentença
            </p>
            <div fxLayout="row" fxLayoutGap="27px">
                <mat-form-field fxFlex="66" appearance="fill" style="padding:0px !important;">
                    <input matInput formControlName="description" #descricao type="text" class="validate" />
                    <mat-label>Descrição da sentença</mat-label>
                    <mat-hint align="end">{{descricao.value.length}} / 255</mat-hint>
                </mat-form-field>
                <div fxLayout="column">
                    <span>Rodar sentença em lotes <mat-icon class="icon-lotes"
                           customToolTip [contentTemplate]="lotes">help</mat-icon>
                           
                    </span>

                    <mat-slide-toggle color="primary" formControlName="in_batch" #in_batch>{{in_batch.checked ?
                        'Ativado':'Desativado'}}</mat-slide-toggle>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="27px">
                <mat-form-field fxFlex=33 appearance="fill">
                    <mat-label>Horário de execução</mat-label>
                    <input placeholder="hh:mm" matInput [specialCharacters]="[':']" [dropSpecialCharacters]="false"
                        mask="00:00" matInput formControlName="first_execution" type="text" class="validate" />
                    <mat-icon matSuffix>access_time</mat-icon>
                    <mat-hint><span style="color:#FFB946">Atenção!</span> Horário estimado para primeira exec.
                    </mat-hint>
                    <mat-icon class="icon-info last"
                        matTooltip="Caso não seja cadastrado um horário, o sistema escolherá automaticamente"
                        matTooltipClass="tooltip-avaliacao mat-elevation-z8" matTooltipPosition="after">help</mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="33" appearance="fill">
                    <mat-select formControlName="interval" required>
                        <mat-option *ngFor="let interval of intervals" [value]="interval.id">
                            {{interval.description}}
                        </mat-option>
                    </mat-select>
                    <mat-label>Intervalo entre execuções</mat-label>
                </mat-form-field>
                <div fxLayout="column" fxFlex="33">
                    <span>Pausar execução automática </span>
                    <mat-slide-toggle color="primary" formControlName="paused" #paused>{{paused.checked ?
                        'Ativado':'Desativado'}}</mat-slide-toggle>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="27px">
                <mat-form-field fxFlex="33" appearance="fill">
                    <input matInput formControlName="category" type="text" [(ngModel)]="category"
                        [matAutocomplete]="categoria">
                    <mat-autocomplete #categoria="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
                            {{ category.titulo }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-label>Categoria da sentença</mat-label>
                </mat-form-field>
                <div fxFlex=33>
                    <div>
                        <mat-form-field fxFlex=48 appearance="fill" >                  
                            <mat-label style="font-size: 14px;">Dia(s)</mat-label>
                                <input matInput formControlName="cacheDays" type="number" mim ="0" max="7" class="validate"/>
                        </mat-form-field>
                        <div fxFlex=2></div>
                        <mat-form-field fxFlex=48 appearance="fill">
                            <mat-label style="font-size: 14px;">Hora(s)</mat-label>
                                <input matInput formControlName="cacheHours" type="number" mim ="0" max="24" class="validate"/> 
                                <mat-icon class="icon-info last" style="margin-left: 8px;"
                                matTooltip="Ajuste a validade do cache para garantir que todas as alterações recentes sejam enviadas a Plataforma Rubeus."
                                matTooltipClass="tooltip-avaliacao mat-elevation-z8" matTooltipPosition="after">help</mat-icon> 
                        </mat-form-field>
                    </div>
                    <mat-hint class="dual-hint">Tempo verificação de cache em dias e horas.</mat-hint>                    
                </div>
                <div fxFlex="33"></div>
            </div>
        </form>
    </div>
</div>
<footer fxLayout="row" fxLayoutAlign="space-between center" class="tool-bar-footer"
    [@hasFooter]="(form.dirty && hasChange) || hasDeletedChips ? 'open' : 'closed'">
    <span class="text-alert">Cuidado. Você tem alterações que não foram salvas!</span>
    <div fxLayoutGap="20px">
        <button type="button" mat-stroked-button (click)="onReset()">
            {{sentece.data ? 'Redefinir':'Cancelar'}}
        </button>
        <button mat-raised-button (click)="onSubmit()" [disabled]=!form.valid>Salvar</button>
        <button mat-raised-button (click)="executeSentence()" [disabled]=!form.valid>Salvar e executar</button>
    </div>
</footer>

<ng-template #lotes>
    <div class="">
        <span>
            Para a execução em lotes, a consulta precisa conter os parâmetros :INICIO e :FIM.
            <br>
            <a class="saiba" href="https://crmrubeus.zendesk.com/hc/pt-br/articles/4407254131853-Como-integrar-Rubeus-com-TOTVS" target="_blank" color="primary">Saiba mais</a> 
        </span>
        
    </div>
</ng-template>