<div fxLayout="column" class="ps-row" fxLayout.gt-xs="row" class="ps-crud-header">
    <div fxFlex fxLayoutAlign="start center">
      <button 
        mat-raised-button 
        (click)="functionFieldCreate()">Vincular Campos</button>
    </div>
   
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" >
  <mat-form-field fxLayoutAlign floatLabel="never" class="ps-crud-input-pesq">
    <mat-icon matPrefix>search</mat-icon>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Pesquisar">
  </mat-form-field>

    <mat-paginator fxLayoutAlign="end center" [pageSizeOptions]="[25, 50, 100]">
    </mat-paginator>
</div>

<table mat-table #table [dataSource]="dataSource" class="ps-crud-table" matSort >
    <ng-container *ngFor="let column of columnsFields" [matColumnDef]="column.columnDef">
  
        <div
          *ngIf="column.columnDef !== 'check' && column.columnDef !== 'buttonEdit' && column.columnDef !== 'buttonLink' && column.columnDef !== 'buttonDelete' && column.columnDef !== 'options'">
          <th mat-header-cell *matHeaderCellDef disabled> {{column.header}} </th>
          <td td mat-cell *matCellDef="let row">
            <span [title]="column.dataName(row)"> {{ (column.dataName(row).length>50) ? (column.dataName(row) | slice:0:50)+'...':(column.dataName(row)) }} </span>
          </td>
        </div>  
    
    
        <div *ngIf="column.columnDef === 'buttonEdit'">
          <th mat-header-cell *matHeaderCellDef></th>
          <td td mat-cell *matCellDef="let row" style="text-align: center; width: 10px;">
            <button
              mat-button
              class="ps-crud-button-tr"
              id="column.dataName(row)"
              (click)="functionFieldEdit(column.dataName(row))">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </div>
        
        
    
        <div *ngIf="column.columnDef === 'buttonDelete'">
          <th mat-header-cell *matHeaderCellDef></th>
          <td td mat-cell *matCellDef="let row" style="text-align: center; width: 10px;">
            <button
              mat-button
              class="ps-crud-button-tr"
              id="column.dataName(row)"
              (click)="functionFieldDelete(column.dataName(row))">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </div>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{isVisible: selectedLineId === row.id}"></tr>
</table>