<div mat-dialog-title class="ps-header-dialog" fxLayoutAlign="start center">
    <div fxFlex="" fxLayoutAlign="start center" fxLayout="row" class="ps-dialog-title">
        <div fxFlex="">{{dados.titleDialog}}</div>
        <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="container rb-padding-16x20">
        <p>
            {{data.data.titulo}}
        </p>
        <p>
            {{data.data.subtitulo}}
        </p>
</mat-dialog-content>
    <!-- <mat-dialog-actions >           
        <div fxLayout="row" fxFill>
            <div cdkFocusInitial fxFlex fxLayoutAlign="end center" class="ps-dialog-content-actions">
                <a mat-stroked-button tabindex="-1" [mat-dialog-close]="data.data.buttonLeft.value"> {{data.data.buttonLeft.label}}</a>
                <button mat-raised-button style="background-color: #FF0031!important;" tabindex="-1" [mat-dialog-close]="data.data.buttonRigth.value"> {{data.data.buttonRigth.label}}</button>
            </div>
        </div>
    </mat-dialog-actions> -->

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
        <a mat-stroked-button style="color: black !important;" 
        [mat-dialog-close]="data.data.buttonLeft.value">{{data.data.buttonLeft.label}}</a>
        <button style="margin-right: -3px;" fxLayoutAlign type="button" class="btn btn-danger" 
        [mat-dialog-close]="data.data.buttonRigth.value">{{data.data.buttonRigth.label}}</button>
    </mat-dialog-actions>