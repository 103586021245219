import { HostListener } from '@angular/core';
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { CrudService } from './crud.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';

@Component({
  selector: 'rb-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss']
})
export class CrudComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() displayedColumns: string[];
  @Input() receivedData: any;
  @Input() descCrudEmpty: string;
  @Input() columns: any[] = [];
  @Input() columnFiltered: any[] = [];
  @Input() metaCount: number;
  @Input() msgCrudEmpty: string;
  @Input() menuOptions: boolean;
  @Input() parentScope: any;
  @Input() dragDrop: any;
  @Input() dragAction: any;
  @Input() disableSort: any;
  @Input() noHasPaginator: boolean;
  @Input() noHasSearch: boolean;
  @Input() minWidthScreenToSticky: number;
  @Input() pageSizeOptions: Array<number> = [5, 10, 25, 50, 100, 200];
  @Input() initializePageSizeOptions = 10;
  @Input() filterFront:boolean = true;

  @Output() clickedButtonEdit = new EventEmitter();
  @Output() clickedButtonLink = new EventEmitter();
  @Output() clickedButtonDelete = new EventEmitter();
  @Output() pageEvent = new EventEmitter<PageEvent>();
  @Output() clickedButtonFilter = new EventEmitter();

  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);

  // @ViewChild(MatSort, {static: true}) sort: MatSort;
  mSort: any;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.mSort = sort;
  }

  @ViewChild('rbCrudContainer', { static: true }) rbCrudContainer;

  public pageIndex = 0;
  pageSize: number;
  length: any;

  triggerFilter: boolean;
  displayFilter: boolean;

  public selectedLineMenu: any = {};
  public selectedLineId: any;
  public dataToFilter: any = {};

  isDrag: boolean;
  isDraggableTable: boolean = false;
  draggableDisableParam: any = '';
  atictiveFiltered = false;
  public rbCrudContainerWidth: any;
  public screenWidth: any;

  constructor(private crudService: CrudService,private router: Router) {
    // Status filter
    this.triggerFilter = false;
    this.displayFilter = crudService.statusFilter;    
  }

  @HostListener('window:resize', ['$event'])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
    this.rbCrudContainerWidth = this.rbCrudContainer
      && this.rbCrudContainer.nativeElement
      && this.rbCrudContainer.nativeElement.offsetWidth ?
      this.rbCrudContainer.nativeElement.offsetWidth + 'px' : 'auto';
  }

  ngOnInit() {
    this.verifyIsDraggableTable();
    this.getScreenWidth();


    this.columnFiltered.map((column) => {
      if (column.default && column.date) {
        this.atictiveFiltered = true;
        this.dataToFilter[column.property] = { value: column.default, date: column.date ? column.date : false };
      } else if (column.default && !column.date && column.default.length > 0) {
        this.atictiveFiltered = true;
        this.dataToFilter[column.property] = { value: column.default, date: column.date ? column.date : false };
      }
    });

    this.triggerFilter = this.atictiveFiltered;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.mSort;
    this.crudService.rowsCrud(this.dataSource, this.receivedData, this.table, this.paginator, this.mSort);
  }

  ngOnChanges() {
    if (this.columns !== undefined || this.columns !== null) {
      this.dataSource = new MatTableDataSource(this.receivedData);
      this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
        const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
          return (currentTerm + (data as { [key: string]: any })[key] + '◬');
        }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        return dataStr.indexOf(transformedFilter) != -1;
      };

      this.displayedColumns = this.columns.map(x => x.columnDef);

      if (this.noHasPaginator) {
        this.dataSource.paginator = null;
      } else if (this.paginator) {
        this.pageSize = this.noHasPaginator ? 200 : this.initializePageSizeOptions;
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSize = this.pageSize;
        this.dataSource.paginator.pageIndex = this.pageIndex;
        this.dataSource.paginator.length = this.metaCount;
      }


      this.crudService.rowsCrud(this.dataSource, this.receivedData, this.table, this.paginator, this.mSort);
    }
  }

  applyFilter(filterValue: string) {
    if (filterValue != '') {
      this.dataToFilter['search'] = { value: filterValue, date: false };
      this.actionButtonFiltered();
    } else {
      delete this.dataToFilter['search'];
      this.actionButtonFiltered();
    }
    // filterValue = filterValue.trim(); // Remove whitespace
    // filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    // this.dataSource.filter = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // if (this.dataSource.paginator) {
    //   if (this.pageIndex) {
    //     this.dataSource.paginator.pageIndex = this.pageIndex - 1;
    //   }
    //   this.dataSource.paginator.firstPage();
    // }
  }

  cleansAllFilters() {
    for (const key in  this.dataToFilter) {
      if (key != 'search') {
        delete this.dataToFilter[key];
       
        for (let i = 0; i < this.columnFiltered.length; i++) {
          if (this.columnFiltered[i]['date']) {
            this.columnFiltered[i]['default'] = null;
          } else {
            this.columnFiltered[i]['default'] = [];
          }
        }
      }
    }
    this.actionButtonFiltered();
  }

  actionButtonEdit(param) {
    this.clickedButtonEdit.emit(param);
  }

  actionButtonLink(param) {
    this.clickedButtonLink.emit(param);
  }

  actionButtonDelete(param) {
    this.clickedButtonDelete.emit(param);
  }

  actionButtonFiltered() {
    this.clickedButtonFilter.emit(this.dataToFilter);
  }


  activeFilter() {
    if (this.triggerFilter === false) {
      this.triggerFilter = true;
    } else {
      const getAttribute = document.getElementById('triggerFilter');
      getAttribute.classList.remove('scale-in-ver-top');
      getAttribute.classList.add('scale-out-ver-top');
      setTimeout(() => {
        this.triggerFilter = false;
      }, 250);
    }
  }

  updateProductsTable(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1; // API starts 1, Mat-Table starts at 0
    this.pageEvent.emit(event);
  }

  dropTable(event?) {
    let disabledParam = null;
    if (this.columns && this.columns[0] && this.columns[0].disabledParam) {
      disabledParam = this.columns[0].disabledParam;
    }

    if (disabledParam) {
      const index = this.setIndexOrder(event);
      let blocked = false;
      if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {
        for (let i = index.first; i <= index.last; i++) {
          if (this.dataSource.data[i] && this.dataSource.data[i][disabledParam]) {
            blocked = true;
          }
        }
      }
      if (!blocked) {
        this.updateDrag(event);
      }
    } else {
      this.updateDrag(event);
    }

  }

  setIndexOrder(event) {
    if (event.previousIndex < event.currentIndex) {
      return { first: event.previousIndex, last: event.currentIndex };
    } else {
      return { first: event.currentIndex, last: event.previousIndex };
    }
  }


  updateDrag(event) {
    const prevIndex = this.dataSource.data.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataSource.data, prevIndex, event.currentIndex);
    this.dataSource.data = this.dataSource.data;
    this.table.renderRows();
    if (this.dragAction && typeof this.dragAction === 'function') {
      this.dragAction(event, this.parentScope);
    }
  }

  dragStarted() {
    this.isDrag = true;
  }

  dragEnded() {
    this.isDrag = false;
  }

  verifyIsDraggableTable() {
    if (this.columns && this.columns.length) {
      for (const item of this.columns) {
        if (item.isDragDrop) {
          this.isDraggableTable = true;
          this.draggableDisableParam = item.disabledParam;
        }
      }
    }
  }

  verifyIsDraggableRow(row) {
    let response = false;
    if (row[this.draggableDisableParam]) {
      response = true;
    }
    return response;
  }

  canDragDrop() {
    if (this.dragDrop === true || this.dragDrop === 'true' || typeof this.dragDrop === 'undefined') {
      return false;
    } else {
      return true;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.crudService.getSelection(this.selection);
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: { id: number; }): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  ngOnDestroy(): void {
    this.crudService.statusFilterDefault.next(false);
  }

  saveSelectedLineMenu(row) {
    this.selectedLineMenu = row;
    this.selectedLineId = row.id;

  }

  filterChange(column, elemento, date = false, search = '') {
    
    if ((!date && elemento.value.length == 0) || (elemento.value == null)) {
      delete this.dataToFilter[column];
    } else {
      this.dataToFilter[column] = { value: elemento.value, date: date };
    }

    if (this.filterFront) {
      this.actionButtonFiltered();
    }
  }

  onClickButton(event: MouseEvent) {
    event.preventDefault();
    window.open('edit/' + String(this.selectedLineMenu.id));
  }
}

