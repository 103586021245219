  <mat-form-field style="min-width: 204px;">
    <mat-select [formControl]="elementMultiCtrl" [placeholder]="placeholder" [multiple]="true"  [(ngModel)]="elementsSelected" #multiSelect>
      <mat-option>
        <ngx-mat-select-search [showToggleAllCheckbox]="true"
                               [toggleAllCheckboxIndeterminate]="isIndeterminate"
                               [toggleAllCheckboxChecked]="isChecked"
                               (toggleAll)="toggleSelectAll($event)"
                               placeholderLabel="Pesquisar..."
                               noEntriesFoundLabel="Nenhuma opção encontrado"
                               [formControl]="elementMultiFilterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let element of filteredElementsMulti | async" [value]="element">
        {{element.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

