import { CrudTbcService } from './../../../crud-tbc/crud-tbc.service';
import { NoticeComponent } from './../../../notice/notice.component';
import { DataService } from './../../../../services/data/data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-dialog-tbc',
  templateUrl: './dialog-tbc.component.html',
  styleUrls: ['./dialog-tbc.component.scss'],
})
export class DialogTbcComponent implements OnInit {

  tbc: FormGroup

  public valueInicial: {
    tbc: null,
    discription: null,
    totvs_user: null,
    totvs_pass: null,
    license_not_required: null
  };


  hide = true;
  visible = true;
  removable = true;
  addOnBlur = true;
  selectable = true;
  execute = false;
  loading = 0;

  public actions: any = [];
  public actionsChips: any = [];

  intervals: any;
  event: any = [];
  channels: any;
  events: any;
  ocultarButton = false;
  userRubeus: boolean;
  tbcSuccess: boolean;
  tbcSuccessMsg: any;
  public dados: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogTbcComponent>,
    private crudTbcService: CrudTbcService,
    private service: DataService,
    private notice: NoticeComponent) {
      this.dados = data;
    }



  ngOnInit() {
    this.tbc = this.formBuilder.group({
      tbc: [this.data.data != null ? this.data.data.tbc : null, Validators.required],
      discription: [this.data.data != null ? this.data.data.discription : null, Validators.required],
      totvs_user: [this.data.data != null ? this.data.data.totvs_user : null, Validators.required],
      totvs_pass: [null],
      license_not_required: [this.data.data != null ? this.data.data.license_not_required : false]
    });

    setTimeout(() => {
      this.valueInicial = this.tbc.value;
      this.valueInicial.discription = this.tbc.value.discription;
      this.valueInicial.totvs_user = this.tbc.value.totvs_user;
      this.valueInicial.totvs_pass = this.tbc.value.totvs_pass;
      this.valueInicial.license_not_required = this.tbc.value.license_not_required;
    }, 0)

    this.tbcSuccess ? this.data.data.tbc : null;
    
    var email = localStorage.getItem('email');
    this.userRubeus = this.extrairDominio(email) == 'rubeus.com.br' ? true : false;
  };

  get alteracao() {

    var isModified = JSON.stringify(this.tbc.value) != JSON.stringify(this.valueInicial);
    return isModified;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    var sentData: any;
    var id: string = '';

      sentData = this.tbc.value;
      sentData['totvs_pass'] = !sentData.totvs_pass ? this.data.data.totvs_pass : sentData.totvs_pass;

    sentData['client'] = localStorage.getItem('client');
    sentData['client_id'] = localStorage.getItem('client');
    sentData['user'] = localStorage.getItem('user');
    sentData['jwt'] = localStorage.getItem('token');
    if (this.data.data != null) {
      id = '/' + this.data.data.id;
    }

    this.service.resolve('UpdateTbc' + id, sentData).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data);


        if (id) {
          sentData['id'] = this.data.data.id;
          this.crudTbcService.updateRowData(sentData);
        } else {
          sentData['id'] = data.id;
          this.crudTbcService.addRowData(sentData);
        }
        this.dialogRef.close();
      } else {
        this.notice.showMessageError(data);
      }
    });
  }

  verifiyTbc() {
    this.loading += 1;
    var linkTbc = this.tbc.value;
    linkTbc.totvs_pass = !linkTbc.totvs_pass ? this.data.data.totvs_pass : linkTbc.totvs_pass;
    var linkString = JSON.stringify(linkTbc);

    this.service.resolve('validate-tbc', linkString).then((data) => {
      this.loading = 0;      
      console.log(data);
      this.tbcSuccess = data.success;
      this.tbcSuccessMsg = data.msg;
    });
  }

   extrairDominio(email) {
    const indexArroba = email.indexOf('@'); 
    if (indexArroba !== -1) {
      return email.substring(indexArroba + 1);
    }
  }
}


