import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CrudFieldsService {
    
  public statusFilterDefault = new BehaviorSubject(false);
  currentStatusFilter = this.statusFilterDefault.asObservable();
  statusFilter: boolean;
  public statusInfoCrudDefault = new BehaviorSubject(false);
  currentStatusInfoCrud = this.statusInfoCrudDefault.asObservable();
  statusInfoCrud: boolean;
  textInfoCrud: string;
  dataSource: any;
  receivedData: any;
  table: any;
  paginator: any;

  constructor() { }

  changeStatusFilter(parameter: boolean) {
    this.currentStatusFilter.subscribe(d => this.statusFilter = d);
    this.statusFilterDefault.next(parameter);
  }

  getStatusFilter() { }
  // paramentros:
  // configurações do hover: quais botoes vai querer
  // tipo isso: e na parte do [buttonOptions]="funcao1, funcao2, funcao3, funcao4"
  // <app-crud [buttonEdit]="nome da função"></app-crud>
   
  rowsCrud(dataSource, receivedData, table, paginator) {
    this.dataSource = dataSource;
    this.receivedData = receivedData;
    this.table = table;
    this.paginator = paginator;
    this.refreshTable();
  }
  addRowData(rowObj) {
    if (typeof this.dataSource === 'undefined') {
      const array = [];
      array.push(rowObj);
      setTimeout(() => {
        location.reload();
      }, 300);
    } else if (typeof this.dataSource.data === 'undefined') {
      const array = this.dataSource;
      array.push(rowObj);
      this.table.renderRows();
      this.refreshTable();
      this.paginator.length = array.length;
    } else {
      const array = this.dataSource.data;
      array.push(rowObj);
      this.dataSource.data = array;
      this.table.renderRows();
      this.refreshTable();
      this.paginator.length = array.length;
    }
  }
  updateRowData(rowObj) {
    this.dataSource.data = this.dataSource.data.filter((value, key) => {
      if (value.id === rowObj.id) {
        // tslint:disable-next-line: forin
        for (const index in rowObj) {
          value[index] = rowObj[index];
        }
      }
      return true;
    });
    this.table.renderRows();
    this.refreshTable();
  }
  deleteRowData(rowObj) {
    if (typeof this.dataSource.data === 'undefined') {
      const array = this.receivedData;
      const result = this.arrayRemove(array, Number(rowObj));
      this.receivedData = result;
      this.refreshTable();
      this.table.renderRows();
      this.paginator.length = result.length;
    } else {
      const array = this.dataSource.data;
      const result = this.arrayRemove(array, Number(rowObj));
      this.dataSource.data = result;
      this.refreshTable();
      this.table.renderRows();
      this.paginator.length = result.length;
    }
  }
  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }
  arrayRemove(arr, value) {
    return arr.filter((ele) => {
      return ele.id !== value;
    });
  }

}