<div class="rb-crud-container" id="rb-crud-container" #rbCrudContainer>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="ps-row">
    <div fxFlex.xs="100" fxFlexOrder.xs="2" fxLayoutAlign="start center" *ngIf="noHasSearch!==true"
      class="rb-crud-search-container" style="max-height: 68px;">
      <mat-form-field floatLabel="never" class="ps-crud-input-pesq">
        <mat-icon matPrefix class="rb-crud-search-icon">search</mat-icon>
        <input matInput class="rb-crud-search-input" (keyup)="applyFilter($event.target.value)" placeholder="Pesquisar">
      </mat-form-field>
      <mat-button-toggle class="ps-crud-button-filter" (click)="activeFilter()" *ngIf="displayFilter">
        <mat-icon class="ps-crud-button-icon-filter">
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.7537 0.631958H1.16146C0.536911 0.631958 0.221764 1.33923 0.664294 1.75226L6.08252 6.81003V12.4445C6.08252 12.6586 6.19446 12.8593 6.38243 12.9821L8.72618 14.5128C9.18857 14.8149 9.83252 14.5087 9.83252 13.9752V6.81003L15.2509 1.75226C15.6925 1.34005 15.3795 0.631958 14.7537 0.631958Z"
              fill="#808080" />
          </svg>
        </mat-icon>
      </mat-button-toggle>
    </div>
    <div fxFlex=""></div>
    <div fxFlex.xs="100" fxFlexOrder.xs="1" fxLayoutAlign="end center" fxLayoutAlign.xs="start center"
      class="rb-crud-search-container" [hidden]="noHasPaginator" [ngClass]="{'rbHideCrudPaginator': noHasPaginator}">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [disabled]="noHasPaginator"
        (page)="updateProductsTable($event)">
      </mat-paginator>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="ps-crud-content-filter scale-in-ver-top" *ngIf="triggerFilter"
    id="triggerFilter">
    <div class="force-position" fxFlex fxLayout="row wrap" fxLayoutGap="20px" *ngIf="columnFiltered">
      <div fxLayout="row" fxLayoutAlign="start start" *ngFor="let filtered of columnFiltered">
        <!-- <mat-form-field *ngIf="!filtered.date">
          <mat-label>{{filtered.header}}</mat-label>
          <mat-select [(value)]="filtered.default" (selectionChange)="filterChange(filtered.property, $event)">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let option of filtered.options" [(value)]="option.id">{{option.label}}</mat-option>
          </mat-select>
        </mat-form-field> -->


        <select-search *ngIf="!filtered.date" [elements]="filtered.options" [(elementsSelected)]="filtered.default"
          [placeholder]="filtered.placeholder" (onChangeSelectSearch)="filterChange(filtered.property, $event)"></select-search>

        <mat-form-field color="primary"*ngIf="filtered.date">
          <input matInput [placeholder]="filtered.placeholder" [satDatepicker]="picker2" [(ngModel)]="filtered.default"
            (dateInput)="filterChange(filtered.property, $event, true)"
            (dateChange)="filterChange(filtered.property, $event, true)">
          <sat-datepicker #picker2 [rangeMode]="true">
          </sat-datepicker>
          <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
        </mat-form-field>

      </div>
    </div>
    <div style="padding-bottom: 24px;align-items: center;"  *ngIf="columnFiltered " fxLayoutGap="20px">
      <button mat-stroked-button (click)="cleansAllFilters();" >Limpar filtro</button>
      <button *ngIf="!filterFront" mat-button mat-raised-button color="primary" (click)="actionButtonFiltered()">Filtrar</button>
    </div>
    <!-- <p>Ta em beta</p> -->
  </div>
  <!-- *ngIf="dataSource && dataSource.filteredData && dataSource.filteredData.length" -->
  <div class="rb-crud-sticky-container" style="padding-top: 16px;"
    [hidden]="!dataSource || !dataSource.filteredData || !dataSource.filteredData.length"
    [ngStyle]="{'max-width': rbCrudContainerWidth}">
    <table mat-table #table matSort [matSortDisabled]="disableSort" [dataSource]="dataSource" class="ps-crud-table"
      [ngClass]="{'rb-crud-table-active': isDrag}" [cdkDropListDisabled]="canDragDrop()" cdkDropList
      (cdkDragStarted)="dragStarted()" [cdkDropListData]="dataSource" (cdkDropListDropped)="dropTable($event)">
      <ng-container *ngFor="let column of columns; let i of index" [matColumnDef]="column.columnDef"
        [sticky]="column.isFixedColumn && screenWidth >= minWidthScreenToSticky"
        [stickyEnd]="column.isFixedColumnEnd && screenWidth >= minWidthScreenToSticky">
        
        <div *ngIf="!column.isCheckBox && !column.isButton && !column.isDragDrop && !column.isIcon">
          <th mat-header-cell *matHeaderCellDef >
            {{column.header}}
          </th>
          <td mat-cell *matCellDef="let row" class="rb-crud-simple-text-col"
            [style.cursor]="column.action ? 'pointer': 'default'"
            (click)="column.action ? column.action(row, parentScope) : ''">
            <span [ngClass]="{'rb-text-decoration-action': column.action}">
              {{(column.dataName(row) && column.dataName(row)!=='' && column.dataName(row)!==null &&
              column.dataName(row)!='undefined') ? column.dataName(row) : '--'}}
            </span>
          </td>
        </div>


        <div *ngIf="column.isDragDrop">
          <th mat-header-cell *matHeaderCellDef class="rb-drag-indicator-column"></th>
          <td mat-cell *matCellDef="let row">
            <mat-icon cdkDragHandle style="pointer-events:all;">drag_indicator</mat-icon>
          </td>
        </div>

        <div *ngIf="column.isCheckBox">
          <th mat-header-cell *matHeaderCellDef class="rb-checkbox-column">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()" color="primary"
              [disabled]="column.disabledParam">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" color="primary"
              [disabled]="row[column.disabledParam]">
            </mat-checkbox>
          </td>
        </div>

        <div *ngIf="column.isButton">
          <th mat-header-cell *matHeaderCellDef
            [ngClass]="{'rb-fixed-button-column': column.buttonConfig.isFixed, 'rb-float-button-column': !column.buttonConfig.isFixed}">
            {{column.buttonConfig.isFixed && column.buttonConfig.header ? column.buttonConfig.header : ''}}
          </th>
          <td td mat-cell *matCellDef="let row" class="ps-crud-content-button"
            [ngClass]="{'rb-fixed-button-column': column.buttonConfig.isFixed, 'rb-float-button-column': !column.buttonConfig.isFixed, 'rb-crud-drag-active': isDrag}">
            <button *ngIf="!column.isMenuButton && !column.isToggleButton" mat-button
              [ngClass]="{'rb-float-button': !column.buttonConfig.isFixed}"
              class="ps-crud-button-tr ps-crud-button-link" id="column.dataName(row)"
              [disabled]="row[column.buttonConfig.disabledParam]"
              (click)="column.buttonConfig.action(row, parentScope)">
              <mat-icon *ngIf="column.buttonConfig.icon!=='rbLinkIcon'">{{column.buttonConfig.icon}}</mat-icon>
              <mat-icon *ngIf="column.buttonConfig.icon==='rbLinkIcon'">
                <svg width="18" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.7828 0.861203C13.2563 0.334626 12.6221 0.0712891 11.8804 0.0712891H2.90537C2.16376 0.0712891 1.52948 0.334626 1.00281 0.861203C0.476228 1.38788 0.212891 2.02212 0.212891 2.76377V11.7387C0.212891 12.4803 0.476228 13.1146 1.00281 13.6413C1.52948 14.168 2.16376 14.4313 2.90537 14.4313H11.8803C12.622 14.4313 13.2562 14.168 13.7827 13.6413C14.3094 13.1146 14.5727 12.4803 14.5727 11.7387V2.76377C14.5727 2.02212 14.3093 1.38778 13.7828 0.861203ZM12.1795 7.55037C12.1795 7.81213 12.0578 7.9959 11.8149 8.10193C11.734 8.13307 11.6558 8.14866 11.5811 8.14866C11.413 8.14866 11.2727 8.08936 11.1604 7.97105L9.81428 6.62482L4.82215 11.6172C4.70358 11.7356 4.56347 11.7949 4.40138 11.7949C4.23922 11.7949 4.09897 11.7356 3.98057 11.6172L3.02699 10.6635C2.90855 10.5453 2.84931 10.4048 2.84931 10.2428C2.84931 10.0809 2.90855 9.94061 3.02699 9.82227L8.01941 4.8298L6.67315 3.48357C6.47989 3.30282 6.4363 3.0848 6.5422 2.82916C6.6481 2.58616 6.83206 2.46451 7.09383 2.46451H11.5811C11.7432 2.46451 11.8835 2.52378 12.0021 2.64225C12.1204 2.76059 12.1796 2.90087 12.1796 3.06293V7.55037H12.1795Z"
                    fill="#979797" />
                </svg>
              </mat-icon>
            </button>

            <button mat-button *ngIf="column.isMenuButton"
              [ngClass]="{isVisible: selectedLineId === row.id, 'rb-crud-drag-cursor': '', 'rb-float-button': !column.buttonConfig.isFixed}"
              matTooltip="{{column.buttonConfig.subtitle}}"
              [matMenuTriggerFor]="rbCrudMenu" [matMenuTriggerData]="row"
              [disabled]="row[column.buttonConfig.disabledParam]" (menuOpened)="saveSelectedLineMenu(row)"
              (menuClosed)="selectedLineId=null" class="ps-crud-button-tr rbCrudMenuButton" id="column.dataName(row)">
              <span>{{column.buttonConfig.title}}</span>
              <mat-icon>{{column.buttonConfig?.icon ? column.buttonConfig?.icon : 'arrow_drop_down'}}</mat-icon>
            </button>

            <mat-slide-toggle #toggleButton *ngIf="column.isToggleButton" matTooltip="{{column.buttonConfig.subtitle}}"
              [matTooltipDisabled]="!row[column.buttonConfig.showSubtitleParam]"
              [ngClass]="{'rb-float-button': !column.buttonConfig.isFixed}" class="ps-crud-toggle" color="primary"
              (dragChange)="column.buttonConfig.action(column.dataName(row), parentScope, toggleButton.checked, toggleButton)"
              (toggleChange)="column.buttonConfig.action(column.dataName(row), parentScope, toggleButton.checked, toggleButton)"
              [checked]="row[column.buttonConfig.checkedParam]===true || row[column.buttonConfig.checkedParam]==='true' || row[column.buttonConfig.checkedParam]==1"
              [disabled]="row[column.buttonConfig.disabledParam]">
              {{column.buttonConfig.title}}
            </mat-slide-toggle>

            
          </td>
        </div>

        <div *ngIf="column.isIcon">
          <th mat-header-cell *matHeaderCellDef>{{column.header}}</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon style="margin-left: 20px;" matTooltip="{{column.dataName(row)}}" [ngClass]="{'when-not-have-description': (!column.dataName(row))}">{{ column.dataName(row) ?  column.icon : 'remove' }}</mat-icon>
          </td>
        </div>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{isVisible: (row.id && row.id === selectedLineId), 'rb-crud-drag-cursor': dragDrop}"
        cdkDragLockAxis="y" cdkDrag [cdkDragData]="row"
        [cdkDragDisabled]="!isDraggableTable || verifyIsDraggableRow(row)" (cdkDragStarted)="dragStarted()"
        (cdkDragEnded)="dragEnded()">
      </tr>
    </table>
  </div>
  <div *ngIf="!dataSource || !dataSource.filteredData || !dataSource.filteredData.length"
    class="rbMsgCrudEmptyContainer">
    <p style="text-align: center;">{{ msgCrudEmpty }}</p>
  </div>

  <div fxLayout="row" id="menuOptions">
    <mat-menu #rbCrudMenu="matMenu" class="rbCrudMenu">
      <button mat-menu-item *ngFor="let item of menuOptions" [disabled]="selectedLineMenu[item.disabledParam]"
      [ngClass]="{ocultar:item?.visible && selectedLineMenu[item.visible] == item.visibleValue}"
        (click)="item.action(selectedLineMenu, parentScope)"  (auxclick)="onClickButton($event)">
        <mat-icon *ngIf="item.icon!=='rbLinkIcon' && item.icon!=='rbDoubleIcon'">{{item.icon}}</mat-icon>
        <mat-icon *ngIf="item.icon==='rbLinkIcon'">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.7828 0.861203C13.2563 0.334626 12.6221 0.0712891 11.8804 0.0712891H2.90537C2.16376 0.0712891 1.52948 0.334626 1.00281 0.861203C0.476228 1.38788 0.212891 2.02212 0.212891 2.76377V11.7387C0.212891 12.4803 0.476228 13.1146 1.00281 13.6413C1.52948 14.168 2.16376 14.4313 2.90537 14.4313H11.8803C12.622 14.4313 13.2562 14.168 13.7827 13.6413C14.3094 13.1146 14.5727 12.4803 14.5727 11.7387V2.76377C14.5727 2.02212 14.3093 1.38778 13.7828 0.861203ZM12.1795 7.55037C12.1795 7.81213 12.0578 7.9959 11.8149 8.10193C11.734 8.13307 11.6558 8.14866 11.5811 8.14866C11.413 8.14866 11.2727 8.08936 11.1604 7.97105L9.81428 6.62482L4.82215 11.6172C4.70358 11.7356 4.56347 11.7949 4.40138 11.7949C4.23922 11.7949 4.09897 11.7356 3.98057 11.6172L3.02699 10.6635C2.90855 10.5453 2.84931 10.4048 2.84931 10.2428C2.84931 10.0809 2.90855 9.94061 3.02699 9.82227L8.01941 4.8298L6.67315 3.48357C6.47989 3.30282 6.4363 3.0848 6.5422 2.82916C6.6481 2.58616 6.83206 2.46451 7.09383 2.46451H11.5811C11.7432 2.46451 11.8835 2.52378 12.0021 2.64225C12.1204 2.76059 12.1796 2.90087 12.1796 3.06293V7.55037H12.1795Z"
              fill="#979797" />
          </svg>
        </mat-icon>
        <mat-icon *ngIf="item.icon==='rbDoubleIcon'">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.6816 0.399902C13.3547 0.399902 13.9004 0.945547 13.9004 1.61865V8.93115C13.9004 9.60426 13.3547 10.1499 12.6816 10.1499H5.36914C4.69604 10.1499 4.15039 9.60426 4.15039 8.93115V1.61865C4.15039 0.945547 4.69604 0.399902 5.36914 0.399902H12.6816ZM5.36914 10.9624C4.24911 10.9624 3.33789 10.0512 3.33789 8.93115V3.6499H2.11914C1.44604 3.6499 0.900391 4.19555 0.900391 4.86865V12.1812C0.900391 12.8543 1.44604 13.3999 2.11914 13.3999H9.43164C10.1047 13.3999 10.6504 12.8543 10.6504 12.1812V10.9624H5.36914Z"
              fill="black" fill-opacity="0.87" />
          </svg>
        </mat-icon>
        <span>{{item.title}}</span>
      </button>
    </mat-menu>
  </div>
</div>