import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NoticeComponent } from 'src/app/components/notice/notice.component';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-dialog-delete-sentence',
  templateUrl: './dialog-delete-sentence.component.html',
  styleUrls: ['./dialog-delete-sentence.component.scss']
})
export class DialogDeleteSentenceComponent implements OnInit {

name = this.data.data.sentence;
id = this.data.data.id;
private payload: object;
public dataSource: any[] = new Array();
public dados: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogDeleteSentenceComponent>,
    private service: DataService,
    private router: Router,
    private notice: NoticeComponent
  ) {
    this.dados = data;
  }

  ngOnInit() {

    const state = localStorage.getItem('state');

    if (state != 'ok') {
      this.router.navigate(['']);
    }

    this.payload = {
      client : localStorage.getItem('client'),
      user : localStorage.getItem('user'),
      jwt : localStorage.getItem('token')
    };

  }

  deleteSentence() {
    this.service.resolve('deleteSentence/' + this.id, this.payload).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data);
        this.spliceCrud(this.id);
      } else {
        this.notice.showMessageError(data);
      }
    });setTimeout(() => {
      location.reload();
    }, 300);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  spliceCrud(id) {
    for (const [index, item] of this.dataSource.entries()) {
      if (item.id == id) {
        this.dataSource.splice(index, 1);
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
