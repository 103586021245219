import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { SentencesComponent } from './components/sentences/sentences.component';
import { SettingsComponent } from './components/settings/settings.component';
import { RouteGuardService } from './guards/route-guard.service';
import { NotauthorizedComponent } from './components/notauthorized/notauthorized.component';
import { LogIntegrationComponent } from './components/log-integration/log-integration.component';
import { EditSenteceComponent } from './components/edit-sentece/edit-sentece.component';
import { SenteceResolver } from './services/resolve/sentece.resolver';
import { CrudCategoryComponent } from './components/crud-category/crud-category.component';
import { CategoryResolver } from './services/resolve/category.resolver';
import { FieldsComponent } from './components/fields/fields.component';

const routes: Routes = [
  { path: 'auth/:token', component: AuthComponent },
  { path: '', redirectTo: 'sentences', pathMatch: 'full' },
  { path: 'sentences', component: SentencesComponent, canActivate: [RouteGuardService] },
  {
    path: 'categories', component: CrudCategoryComponent,
    canActivate: [RouteGuardService],
    resolve: { categories: CategoryResolver },
  },
  { path: 'fields', component: FieldsComponent, canActivate: [RouteGuardService] },
  { path: 'settings', component: SettingsComponent, canActivate: [RouteGuardService] },
  { path: 'log-integration', component: LogIntegrationComponent, canActivate: [RouteGuardService] },
  {
    path: 'edit/:id', component: EditSenteceComponent,
    canActivate: [RouteGuardService],
    resolve: { sentece: SenteceResolver },
  },
  {
    path: 'edit', component: EditSenteceComponent,
    canActivate: [RouteGuardService],
    resolve: { sentece: SenteceResolver },
  },
  { path: 'notauthorized', component: NotauthorizedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
