import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material';
import { DecimalPipe } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CrudFieldsService } from './crud-fields.service';

@Component({
  selector: 'app-crud-fields',
  templateUrl: './crud-fields.component.html',
  styleUrls: ['./crud-fields.component.scss']
})
export class CrudFieldsComponent implements OnInit, OnChanges, OnDestroy {

  @Output() clickedFieldCreate = new EventEmitter();
  @Output() clickedFieldDelete = new EventEmitter();
  @Output() clickedFieldEdit = new EventEmitter();

  @Input() displayedColumns: string[];
  @Input() receivedFieldsData: any;
  @Input() tableTitle: string;
  @Input() columnsFields: any[] = [];


  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  selection = new SelectionModel<any>(true, []);
  mSort: any;
  decimal: any;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.mSort = sort;
  }

  pageIndex = 0;
  pageSize = 25;
  length: any;

  public selectedLineMenu: any;
  public selectedLineId: any;

  triggerFilter: boolean;
  displayFilter: boolean;
  isUserRubeus: boolean;


  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private FieldsService: CrudFieldsService) {
    iconRegistry.addSvgIcon(
      'filter',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/filter.svg'));
    iconRegistry.addSvgIcon(
      'link',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/link.svg'));
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/copy.svg'));

    // Status filter
    this.triggerFilter = false;
    this.displayFilter = FieldsService.statusFilter;

  }

  ngOnInit() {
    var decimalPipe = new DecimalPipe(navigator.language);

    this.paginator._intl.itemsPerPageLabel = 'Itens por página: ';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} de ${decimalPipe.transform(length)}`;
    };


  }

  ngAfterViewInit() {
    this.dataSource.sort = this.mSort;
  }

  ngOnChanges() {
    if ((this.columnsFields !== undefined || this.columnsFields !== null) && this.receivedFieldsData !== undefined) {
      this.dataSource = new MatTableDataSource(this.receivedFieldsData);
      this.displayedColumns = this.columnsFields.map(x => x.columnDef);
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.pageSize = this.pageSize;
      this.dataSource.paginator.pageIndex = this.pageIndex;
      // this.dataSource.paginator.length = this.metaCount;
      this.FieldsService.rowsCrud(this.dataSource, this.receivedFieldsData, this.table, this.paginator);
    }
  }

  functionFieldCreate() {
    this.clickedFieldCreate.emit();
  }

  functionFieldEdit(param) {
    this.clickedFieldEdit.emit(param);
  }

  functionFieldDelete(param) {
    this.clickedFieldDelete.emit(param);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

   checkboxLabel(row?: { id: number; }): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  ngOnDestroy(): void {
    this.FieldsService.statusFilterDefault.next(false);
  }

  saveSelectedLineMenu(row) {
    this.selectedLineMenu = row;
    this.selectedLineId = row.id;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getRubeusUserData(isRubeus) {
    this.isUserRubeus = isRubeus;
  }
}
