<div fxLayout="column" fxLayoutAlign="space-evenly stretch">

    <rb-crud
    [(receivedData)] = "dataSource"
    [(columns)] = "columns"
    [(metaCount)] = "dataSource"
    [msgCrudEmpty]="msgCrudEmpty"
    [parentScope]="this"
    [disableSort]= "false"
    [noHasPaginator]="false"
    [noHasSearch]="true"
    >
    </rb-crud>
</div>

