import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataService } from 'src/app/services/data/data.service';
import { SentencesComponent } from "src/app/components/sentences/sentences.component";
import { NoticeComponent } from 'src/app/components/notice/notice.component';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-change-columns-order',
  templateUrl: './dialog-change-columns-order.component.html',
  styleUrls: ['./dialog-change-columns-order.component.scss']
})
export class DialogChangeColumnsOrderComponent implements OnInit {
  removeIndice: any;
  botaoEncontrado: any;
  public dados: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DataService,
    private sentence: SentencesComponent,
    private notice: NoticeComponent,
    public dialogRef: MatDialogRef<DialogChangeColumnsOrderComponent>,
    private router: Router,

  ) {
    this.dados = data;
  }
  public columns = this.data;
  private payload: object;
  @ViewChild('changeButtonHover', { static: false }) private changeButton: ElementRef;

  public backupButtonColumn: any = null;

  ngOnInit() {
    this.payload = {
      client: localStorage.getItem('client'),
      user: localStorage.getItem('user'),
      jwt: localStorage.getItem('token')
    };

    var findButton = this.columns.columns.find(o => o.header === "Botões");

    if (findButton) {
      this.backupButtonColumn = findButton;

      this.columns.columns.splice(this.columns.columns.indexOf('Botões'), 1)
    }

    this.botaoEncontrado = findButton;
  }

  ngAfterViewInit() {
    this.changeButton['_elementRef'].nativeElement.classList.remove('cdk-program-focused');
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns.columns, event.previousIndex, event.currentIndex);
  }

  ModifyColumns() {
    this.columns.columns.push(this.backupButtonColumn);
    var array = this.columns.columns.reduce((columns: Array<any>, element) => {
      columns.push({ header: element.header == null ? this.botaoEncontrado.header : element.header, visibility: element.visibility == null ?  this.botaoEncontrado.visibility : element.visibility });
      return columns;
    }, []);

    console.log(this.columns.columns);
    this.service.resolve('save-columns', [this.payload, JSON.stringify(array)]).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data.msg);
        this.dialogRef.close();
      } else {
        this.notice.showMessageError('error');
      }
    });
  }

  resetColumns() {
    this.service.resolve('reset-columns', this.payload).then((data) => {
      if (data.success) {
        this.columns.columns.forEach(element => {
          element.visibility = true;
        });
        this.notice.showMessageSuccess(data.msg);
        window.location.reload();
        this.dialogRef.close();
      } else {
        this.notice.showMessageError(data.msg);
      }
    });
  }

  hideColumn(column, index) {

    column.visibility = !column.visibility;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
