<div fxLayout="column" fxLayoutAlign="center center">
    <mat-card class="card-info">
        <span>
            Caso o registro seja retornado novamente pela execução da sentença, dentro do período de sete (7) dias, o evento relacionado ao mesmo não será gerado na Plataforma Rubeus.
        </span>
    </mat-card>
    <mat-card>

        <!-- <div style="height: 530px" *ngIf="this.loading === 0" class="container" fxLayout="row" fxLayoutAlign="center center">
            <p>
                <img src="../../assets/icons/rbGif.gif">
            </p>
        </div> -->

        <div *ngIf="this.hasSentences == false && this.loading == 4" fxLayout="column" fxLayoutAlign="center center" style="height: 530px">
            <p><img src="../../assets/icons/integracaototvs.svg"></p>

            <p class="title">Vincule e execute sentenças do TOTVS para o Rubeus</p>
            <p style="margin-bottom: 15px">
                <button color="primary" mat-raised-button (click)="openCreateDialog()" class="btn">Vincular sentença</button>
            </p>
        </div>

        <div *ngIf="this.hasSentences == true && this.loading == 5">
            <div fxLayout="column" class="ps-row" fxLayout.gt-xs="row" class="ps-crud-header" style="margin-top: 15px;">
                <div fxFlex fxLayoutAlign="space-between center">
                  <button
                    mat-raised-button
                    (click)="openCreateDialog()">Vincular Sentença</button>

                    <button
                    #changeButtonHover
                    mat-stroked-button
                    (click)="OpenAlterColumns()"><mat-icon>settings</mat-icon><a> &nbsp; </a>Editar colunas</button>
                </div>


            </div>

            <rb-crud
                [(receivedData)] = "dataSource"
                [(columns)] = "columns"
                [(columnFiltered)]= "columnFiltered"
                (clickedButtonFilter) = "testeFilter($event)"
                [(metaCount)] = "metaCount"
                [msgCrudEmpty]="msgCrudEmpty"
                [menuOptions]="menuOptions"
                [parentScope]="this"
                [dragDrop]= "false"
                [disableSort]= "false"
                [noHasPaginator]="false"
                [noHasSearch]="false"
                [initializePageSizeOptions]="50"
                >
            </rb-crud>
        </div>

    </mat-card>
</div>

