<div fxLayout="row" fxLayoutAlign="center"> 
  <mat-card>
    <div style="margin-top: 15px;" fxLayout="column" class="ps-row" fxLayout.gt-xs="row" class="ps-crud-header">
      <div fxFlex fxLayoutAlign="start center">
        <button mat-raised-button (click)="functionCategoryCreate()">Cadastrar Categoria</button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" >
      <mat-form-field fxLayoutAlign floatLabel="never"  class="ps-crud-input-pesq">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Pesquisar">
      </mat-form-field>

        <mat-paginator fxLayoutAlign="end center" [pageSizeOptions]="[25, 50, 100]">
        </mat-paginator>
    </div>


    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="ps-crud-table">
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- Nome Column -->
      <ng-container matColumnDef="nome" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> Nome </th>
        <td mat-cell *matCellDef="let element"> {{ (element.titulo.length>100) ? (element.titulo | slice:0:100)+'...':(element.titulo) }} </td>
      </ng-container>

      <!-- senteças Column -->
      <ng-container matColumnDef="quantidade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> Sentenças associadas </th>
        <td mat-cell *matCellDef="let element"> {{element.sentences == 0 ? '--' : element.sentences}} </td>
      </ng-container>

      <!-- button Column-->
      <ng-container matColumnDef="editButton">
        <th mat-header-cell *matHeaderCellDef></th>
        <td td mat-cell *matCellDef="let element" style="text-align: center; width: 10px;">
          <button mat-button class="ps-crud-button-tr" (click)="functionCategoryCreate(element)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="deleteButton">
        <th mat-header-cell *matHeaderCellDef></th>
        <td td mat-cell *matCellDef="let element" style="text-align: center; width: 10px;">
          <button mat-button class="ps-crud-button-tr" (click)="functionCrudDelete(element)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- button Column-->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card>
</div>