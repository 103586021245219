import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;

  constructor(
    private http: HttpClient
  ) { }

  public postToken(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        // Authorization : Header
      })
    };
    return this.http.get(environment.api + 'auth?token=' + payload, httpOptions);
  }
}
