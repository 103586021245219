<div style="margin-top: 15px;" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">

    <div style="display: none;">
        <mat-form-field floatLabel="never" class="ps-crud-input-pesq">
            <mat-icon matPrefix>search</mat-icon>
            <input #search matInput (keyup)="applyFilter($event.target.value)" placeholder="Pesquisar">
        </mat-form-field>
    </div>

    <div style="margin-left: -17;position: relative;" fxLayoutAlign>
        <div fxLayoutAlign>
            <button (click)="applyFilter(null)" class="button-pad" mat-stroked-button>
                <mat-icon style="color: #7F7F7F;padding-top: 2px;font-size: 19px;">loop</mat-icon>
                <a style="color: #7F7F7F">Atualizar dados </a>
            </button>
        </div>
        <mat-button-toggle class="ps-crud-button-filter" (click)="activeFilter()">
            <mat-icon style="color: #989898" matPrefix>filter_alt</mat-icon>
        </mat-button-toggle>

    </div>

    <div fxLayoutAlign="center center">
        <mat-paginator [pageSizeOptions]="[25, 50, 100]">
        </mat-paginator>
    </div>

</div>
<br>

<div [formGroup]="filterform" fxLayout="start center" fxLayoutAlign="space-between center" fxLayoutGap="20px"
    class="back-color padding-filter" *ngIf="triggerFilter" id="triggerFilter">
    <div class="" fxFlex fxLayout="row wrap" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="start start" *ngFor="let filtered of columnFiltered">
            <select-search *ngIf="!filtered.date" [elements]="filtered.options" [(elementsSelected)]="filtered.default"
                [placeholder]="filtered.placeholder"
                (onChangeSelectSearch)="filterChange(filtered.property, $event.value)">
            </select-search>
            <mat-form-field *ngIf="filtered.date" fxLayout="column" class="ps-row" fxLayout.gt-xs="row" class="">
                <input matInput placeholder="Escolha um intervalo" [satDatepicker]="picker2"
                    (dateInput)="filterSelectorDate($event.value, 'created_at')"
                    (dateChange)="filterSelectorDate($event.value, 'created_at')">
                <sat-datepicker #picker2 [rangeMode]="true">
                </sat-datepicker>
                <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
            </mat-form-field>
        </div>
</div>
<div fxLayout="row" fxLayoutGap="20px">

    <div (click)="makeFilterNull()">
        <button style="background-color: transparent;" mat-stroked-button>
            Limpar filtro
        </button>
    </div>

    <div (click)="applyFilter(search.value)">
        <button mat-raised-button>
            Filtrar
        </button>
    </div>
</div>
</div>

<!-- End Filtro -->

<!-- <div *ngIf="this.loadingSearch == 0" class="container"  fxLayout="column" fxLayoutAlign="center center">
    <p>
      <img style="height: 80px;" src="../../assets/icons/rbGif.gif">
  </p>
  <p class="title">Buscando dados, aguarde!</p>
  </div> -->

<div *ngIf="this.loadingSearch >= 2" style="overflow: auto;">
    <mat-card fxLayout="row" fxLayoutAlign="center center">
        <div>

            <p style="padding-left: 98px"><img src="../../assets/icons/integracaototvs.svg"></p>

            <p class="title">Sem registro na base de dados!</p>

        </div>
    </mat-card>
</div>

<div *ngIf="this.loadingSearch == 1" style="overflow: auto;">
    <table mat-table #table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource" class="ps-crud-table ">

        <ng-container *ngFor="let column of columnsLog" [matColumnDef]="column.columnDef">

            <div
                *ngIf="column.columnDef !== 'check' && column.columnDef !== 'buttonEdit' && column.columnDef !== 'buttonLink' && column.columnDef !== 'buttonDelete' && column.columnDef !== 'options'">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disabled> {{column.header}} </th>
                <td td mat-cell *matCellDef="let row">
                    <span [title]="column.dataName(row)"> {{ (column.dataName(row).length>50) ? (column.dataName(row) |
                        slice:0:50)+'...':(column.dataName(row)) }} </span>
                </td>
            </div>



            <div *ngIf="column.columnDef === 'options'">
                <th mat-header-cell *matHeaderCellDef></th>
                <td td mat-cell *matCellDef="let row" style="text-align: center; width: auto;">
                    <button style="  text-align: center !important;
            padding-right: 10px !important;
            padding-left: 10px !important;" mat-button
                        [ngClass]="{isVisible: selectedLineId === row.id, 'rb-crud-drag-cursor': ''}"
                        class="ps-crud-button-tr rbCrudMenuButton rb-float-button" (click)="functionCrudView( row )"
                        id="column.dataName(row)">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </td>
            </div>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{isVisible: selectedLineId === row.id}">
        </tr>
    </table>
</div>