
import { NoticeComponent } from './../../../notice/notice.component';
import { DataService } from './../../../../services/data/data.service';
import { Component, ComponentRef, OnInit, Inject, ViewChild, ɵConsole } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect, MatAutocompleteTrigger } from '@angular/material';
import { interval, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface Systems {
  name: string;
  code: string;
}

export interface Object {
  name: string;
  code: string;
}

export interface Channels {
  app_id: string;
  id: string;
  link: string;
  nome: string;
}

export interface Actions {
  name: string;
  code: string;
}

export interface Events {
  titulo: string;
  id: string;
  codigo: string;
  origem: string;
  origemNome: string;
}

@Component({
  selector: 'app-dialog-sentence',
  templateUrl: './dialog-sentence.component.html',
  styleUrls: ['./dialog-sentence.component.scss']
})
export class DialogSentenceComponent implements OnInit {

  componentRef: ComponentRef<any>;

  public form: FormGroup;

  private sentencePtrn = "^([[R]{1}[B]{1}[A-Z]{2,}\.[WS]{2}\.[0-9\.]{3,}]{0,})$";
  private parametersPtrn = "^([A-Z]*\=[0-9, A-Z, \_\-\#\%\+\(\)]*\;?){0,}$";

  visible = true;
  removable = true;
  addOnBlur = true;
  selectable = true;
  execute = false;

  public actions: any = [];
  public actionsChips: any = [];

  intervals: any;
  channels: Channels[];
  events: Events[];
  event: any = [];
  tbc_options: any = [];

  isShown: boolean = false ; // hidden by default



  systems: Systems[] = [
    {name: 'S (Educacional)', code: 's'},
    {name: 'G (Global)', code: 'g'},
    {name: 'F (Financeiro)', code: 'f'},
  ];

  actionsSelect: Object[] = [
    {name: 'Unidade', code: 'unit'},
    {name: 'Curso', code: 'course'},
    {name: 'Oferta', code: 'offer'},
    {name: 'Local de oferta', code: 'offerPlace'},
    {name: 'Contato', code: 'contact'},
    {name: 'Evento', code: 'event'},
    {name: 'Processo Seletivo', code: 'selectiveProcess'},
  ];

  filteredEvents: Observable<Events[]>;

  @ViewChild('acts', {static: true}) acts: MatSelect;
  @ViewChild(MatAutocompleteTrigger, {static: false}) _auto: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogSentenceComponent>,
    private service: DataService,
    private notice: NoticeComponent
  ) {

    this.channels = this.data.channels.data;
    this.events = this.data.events.data;
    this.intervals = this.data.intervals.data;
    this.tbc_options = this.data.tbc_options;
  }

  ngOnInit() {
    this.execute = false;
    if (this.data.data) {
      this.actions = this.data.data.action;
      this.event = this.data.data.event;
    }

    this.form = this.formBuilder.group({
      sentence: [ this.data.data != null ? this.data.data.sentence : null, [Validators.required /*, Validators.pattern(this.sentencePtrn) */] ],                                                                                 
      colligate: [ this.data.data != null ? this.data.data.colligate : null, Validators.required ],
      system: [ this.data.data != null ? this.data.data.system : 's', Validators.required ],
      action: [ null ],
      event: [ null ],
      tbc_settings_id: [this.data.data != null ? this.data.data.tbc_settings_id : null ],
      channel: [ this.data.data != null ? this.data.data.origin : null, Validators.required ],
      parameters: [ this.data.data != null ? this.data.data.parameters : null /*, Validators.pattern(this.parametersPtrn)*/ ],
      interval: [ this.data.data != null ? parseInt(this.data.data.interval) : null, Validators.required ],
    });

    this.controlSelectValue();

    this.filteredEvents = this.form.controls.event.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.titulo),
        map(titulo => titulo ? this._filter(titulo) : this.events.slice())
      );
      this.controlAutocompleteValue();
  }

  displayFn(event?: Events): string | undefined {
    return event ? event.titulo : undefined;
  }

  private _filter(titulo: string): Events[] {
    const filterValue = titulo.toLowerCase();

    return this.events.filter(event => event.titulo.toLowerCase().indexOf(filterValue) === 0);
  }

  remove(index): void {
    this.actionsChips.splice(index, 1);
    let rowAll: any
    this.actions = [];
    for (rowAll of this.actionsSelect) {
      for (let rowSelected of this.actionsChips) {
        if (rowSelected.code === rowAll.code) {
          this.actions.push(rowAll.code)
        }
      }
    }
  }

  controlSelectValue() {
    let rowAll: any
    this.actionsChips = [];

    for (rowAll of this.actionsSelect) {
      for (let rowSelected of this.actions) {
        if (rowSelected === rowAll.code) {
          this.actionsChips.push(rowAll)
        }
      }
    }
  }

  controlAutocompleteValue() {
    let rowAll: any

    for (rowAll of this.events) {
      if (this.event === rowAll.id) {
        this.event = rowAll;
      }
    }
  }

  saveSentence() {
    this.dialogRef.close();
  }

  saveExecuteSentence() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  executeSentence() {
    this.execute = true;
    this.dialogRef.close();
  }

  onSubmit() {
    var sentData: any;
    var id: string = '';

    sentData = this.form.value;
    sentData['client'] = localStorage.getItem('client');
    sentData['user'] = localStorage.getItem('user');
    sentData['jwt'] = localStorage.getItem('token');
    sentData['execute'] = this.execute;


    console.log(this.form.value)

    if (this.data.data != null) {
      id = '/' + this.data.data.id;
    }

    this.service.resolve('saveSentence'+id, sentData).then((data) => {
      if (data.success) {
        this.notice.showMessageSuccess(data);
        // this.buildTitles(sentData);

        for (const channel of this.channels) {
          if (channel.id == sentData.channel) {
            sentData['channelName'] = channel.nome;
          }
        }
        
        if (sentData.event != null) {
          for (const event of this.events) {
            if (event.id == sentData.event.id) {
              sentData['eventTitle'] = event.titulo;
            }
          }
        }

        for (const interval of this.intervals) {
          if (interval.id == sentData.interval) {
            sentData['intervalTitle'] = interval.description;
          }
        }
        for (const tbc of this.tbc_options) {
            if (tbc.id == sentData.tbc_settings_id) {
              sentData['tbc'] = tbc.discription
            }


        }

        if (id) {
          sentData['id'] = this.data.data.id;
          sentData['execution'] = data.executed;
        } else {
          sentData['id'] = data.id;
          sentData['execution'] = data.executed;
        }
        this.dialogRef.close(data.data);
      } else {
        this.notice.showMessageError(data);
      }


    });
  }

  buildTitles(array: any[], options, id, titleReference, title) {
    for (const item of array) {
      for (const opt of options) {
        if (opt.id == item[id]) {
          item[title] = opt[titleReference];
        }
      }
    }
    return array;
  }
  
}
