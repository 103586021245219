import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})


export class SenteceResolver implements Resolve<any> {

  public id;
  public objetoCompleto = {
    data: null,
    channels: null,
    intervals: null,
    events: null,
    tbc_options: null,
    categories: null,
    legalBases:null
  };

  public payload = {
    client: localStorage.getItem('client'),
    user: localStorage.getItem('user'),
    jwt: localStorage.getItem('token')
  };

  constructor(private service: DataService) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.id = route.paramMap.get('id');
    if (this.id) {
      return this.service.resolve(
        'sentence/' + this.id, this.payload).then((obj: any) => {
          if (obj && obj.data) {
            this.objetoCompleto.data = obj.data[0];
            return this.service.resolve('channels', this.payload).then((obj) => {
              if (obj && obj.data) {
                this.objetoCompleto.channels = obj.data;
                return this.service.resolve('intervals', this.payload).then((obj) => {
                  if (obj && obj.data) {
                    this.objetoCompleto.intervals = obj.data;
                    return this.service.resolve('events', this.payload).then((obj) => {
                      if (obj && obj.data) {
                        this.objetoCompleto.events = obj.data;
                        return this.service.resolve('getTotvsData', this.payload).then((obj) => {
                          if (obj) {
                            this.objetoCompleto.tbc_options = obj;
                            return this.service.resolve('get-Categories', this.payload).then((obj) => {
                              if (obj && obj.data){
                                this.objetoCompleto.categories = obj.data;
                                return this.service.resolve('legal-Base', this.payload).then((obj) => {
                                  if (obj && obj.data){
                                    this.objetoCompleto.legalBases = obj;
                                    return this.objetoCompleto;
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
    } else {
      this.objetoCompleto.data = null;
          return this.service.resolve('channels', this.payload).then((obj) => {
            if (obj && obj.data) {
              this.objetoCompleto.channels = obj.data;
              return this.service.resolve('intervals', this.payload).then((obj) => {
                if (obj && obj.data) {
                  this.objetoCompleto.intervals = obj.data;
                  return this.service.resolve('events', this.payload).then((obj) => {
                    if (obj && obj.data) {
                      this.objetoCompleto.events = obj.data;
                      return this.service.resolve('getTotvsData', this.payload).then((obj) => {
                        if (obj) {
                          this.objetoCompleto.tbc_options = obj;
                          return this.service.resolve('get-Categories', this.payload).then((obj) => {
                            if (obj && obj.data){
                              this.objetoCompleto.categories = obj.data;
                              return this.service.resolve('legal-Base', this.payload).then((obj) => {
                                if (obj && obj.data){
                                  this.objetoCompleto.legalBases = obj;
                                  return this.objetoCompleto;
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
    }
  }
}
