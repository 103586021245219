import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog, MatSort, MAT_DIALOG_DATA, Sort } from '@angular/material';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { CrudLogService } from './crud-log.service';
import { DataService } from 'src/app/services/data/data.service';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogLogCrudComponent } from '../dialog/template/dialog-log-crud/dialog-log-crud.component';

export interface Method {
  uri: string
  label: string
  tipo: number
}
@Component({
  selector: 'app-crud-log',
  templateUrl: './crud-log.component.html',
  styleUrls: ['./crud-log.component.scss']
})

export class CrudLogComponent implements OnInit {
  @Input() columnsLog: any[] = [];
  @Input() displayedColumns: string[];
  @Input() receivedLogData: any;
  @Input() filters: any[] = [];

  @Output() clickedTbcVisualize = new EventEmitter();

  private payload: object;
  public channels: any;
  public events: any;
  public intervals: any[] = new Array();


  triggerFilter: boolean;
  displayFilter: boolean;
  loadingSearch: number = 1;
  

   filtercolumn: any[] = [];

  sql: any[] = [];
  method: Method[] = [
    {uri: 'totvs', label: 'Consulta totvs', tipo: 1}
  ];
  date: string[] = [];
  success: any[] = [];
  column = {};

  responses = [
    {label: 'true',value: 1},
    {label: 'false', value: 0},
  ];

  filterform: FormGroup

  public noSentences: boolean = false;
  public hasSentences: boolean = false;


  pageIndex = 0;
  pageSize = 25;
  length: any;

  columnFiltered: any[] = [
    {
        placeholder: "SQL",
        property: 'sql',
        date: false,
        options: this.sql,
        default: []
    },
    {
        placeholder: "Método",
        property: 'method',
        date: false,
        options: this.method,
        default: []
      },
      {
        placeholder: "Escolha um intervalo",
        property: 'created_at',
        date: true,
        options: null
        // default: {
        //   begin: null,
        //   end: null
        // }
      },
      {
        placeholder: "Resposta",
        property: 'success',
        date: false,
        options: this.responses,
        default: []
    }
];
  public selectedLineMenu: any;
  public selectedLineId: any;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  selection = new SelectionModel<any>(true, []);
  mSort: any;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
      this.mSort = sort;
  }

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private CrudLogService: CrudLogService, private service: DataService,private formBuilder: FormBuilder, private router: Router,private dialog: MatDialog) {
    iconRegistry.addSvgIcon(
      'filter',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/filter.svg'));
    iconRegistry.addSvgIcon(
      'link',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/link.svg'));
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/icons/copy.svg'));

    // Status filter
    this.triggerFilter = false;
    this.displayFilter = CrudLogService.statusFilter;
    
  }

  ngOnInit() {

    const state = localStorage.getItem('state');

    if (state != 'ok') {
      this.router.navigate(['']);
    }

    this.payload = {
      client : localStorage.getItem('client'),
      user : localStorage.getItem('user'),
      jwt : localStorage.getItem('token')
    };

    this.getLogDataCrud();

    this.filterform = this.formBuilder.group({
      sql: [null],
      method: [null],
      created_at: [null],
      success: [null],
    });

    this.filterSentence();
    this.filterMethod()
  }

  activeFilter() {
    if (this.triggerFilter === false) {
      this.triggerFilter = true;
    } else {
      const getAttribute = document.getElementById('triggerFilter');
      getAttribute.classList.remove('scale-in-ver-top');
      getAttribute.classList.add('scale-out-ver-top');
      setTimeout(() => {
        this.triggerFilter = false;
        this.dataSource.data = this.receivedLogData
        this.filterform.controls.sql.setValue(null);
        this.filterform.controls.method.setValue(null);
        this.filterform.controls.created_at.setValue(null);
        this.filterform.controls.success.setValue(null);
      }, 250);
    }
  }

  makeFilterNull() {
    this.triggerFilter = false;
    this.dataSource.data = this.receivedLogData
    this.filterform.controls.sql.setValue(null);
    this.filterform.controls.method.setValue(null);
    this.filterform.controls.created_at.setValue(null);
    this.filterform.controls.success.setValue(null);
    this.loadingSearch = 1
    this.activeFilter()
  }

  functionCrudView(param) {
    this.clickedTbcVisualize.emit(param);
  }


  saveSelectedLineMenu(row) {
    this.selectedLineMenu = row;
    this.selectedLineId = row.id;
  }
  
  
  ngOnChanges() {
    if ((this.columnsLog !== undefined || this.columnsLog !== null) && this.receivedLogData !== undefined){
      this.getLogDataCrud();
      this.dataSource = new MatTableDataSource(this.receivedLogData);
      this.displayedColumns = this.columnsLog.map(x => x.columnDef);
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.pageSize = this.pageSize;
      this.dataSource.paginator.pageIndex = this.pageIndex;
      // this.dataSource.paginator.length = this.metaCount;
      this.CrudLogService.rowsCrud(this.dataSource, this.receivedLogData, this.table, this.paginator);
    }
  }

  getLogDataCrud() {

    let obj = [];

    obj = this.receivedLogData;
  
  if (obj.length != 0) {
    this.hasSentences = true;

  } else {
    this.noSentences = true;
  }

  }

  getFilterObject(fullObj, key, keyArray = null) {
    const uniqChk = [];

    if (!fullObj) {
      return uniqChk;
    }

    fullObj.filter((obj) => {
      if (keyArray != null) {
        obj[key].map((v) => {
          if (!uniqChk.includes(v[keyArray])) {
            uniqChk.push(v[keyArray]);
          }
        });
      } else {
        if (!uniqChk.includes(obj[key])) {
          uniqChk.push(obj[key]);
        }
      }
      return obj;
    });
    return uniqChk;
  }

  filterSelector(value, columnSearch){

    let data = this.receivedLogData;


    this.column [columnSearch] = value;

    let searchTerms = this.column;
    let isFilterSet = false;

    for (const col in searchTerms) {
      if (searchTerms[col].length > 0) {
        isFilterSet = true;
      } else {
        delete searchTerms[col];
      }
    }

    for (const col in searchTerms) {
      let valid = false;
      data = data.filter((value, key) => {
        valid = false;
        for (let i = 0; i < searchTerms[col].length; i++) {
          /**/

          this.filters.map((v) => {
            if (v.columnProp == col) {
              if (v.collectionCol) {
                value[col].map((x) => {

                  if ((x[v.collectionCol] == searchTerms[col][i]) || (x[v.collectionCol].slice(0, 10) == searchTerms[col][i])) {
                    valid = true;
                  }

                });
              } else {
                if ((value[col] == searchTerms[col][i]) || (value[col].slice(0, 10) == searchTerms[col][i])) {
                  valid = true;
                }
              }
            }
          });
          /***/
        }
        return valid;
      });
    }

    this.dataSource.data = data;
  }


  applyFilter(filterValue: string) {
    if(filterValue == null)
    {
      filterValue = '';
    }
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    if (filterValue.length > 5) {
      this.requestDataFilter(filterValue);
    } else {
      this.requestDataFilter();
    }
  }

    filterSelectorDate(value, column) {
        this.filterform.controls[column].setValue(value);
  }

  filterSentence() {
    this.service.resolve('filter-sentence', this.payload).then((obj) => {
        // this.sql = obj.data;
        this.sql.push(...obj.data);

     return 
    });
  }

  filterMethod() {
    this.service.resolve('filter-method', this.payload).then((obj: any) => {
      this.method.push(...obj.data);

     return this.method
    });
  }

  requestDataFilter(search = null) {

    this.loadingSearch = 0;

    this.filterform.value.search = search;

  
    let valueSubmit = Object.assign({}, this.filterform.value);
    if(this.filterform.value.created_at){
      valueSubmit = Object.assign(valueSubmit, {
        created_at: {
          begin: formatDate(this.filterform.value.created_at.begin, 'yyyy-MM-dd 00:00:00', 'en'),
          end: formatDate(this.filterform.value.created_at.end, 'yyyy-MM-dd 23:59:59', 'en')
        }
      });
    }
  
    this.service.resolve('search-filter', [valueSubmit, this.payload]).then((obj: any) =>{
      if (obj.data.length != 0) {
        
        obj.data.map((element) => { 

          if(element.uri == null) {
            element.uri = "api/Não foi possível achar o caminho!"
          }

          if(element.uri == null) {
            element.uri = 'api/Caminho não encontrado'
          } 

          let index =  element.uri.indexOf('api/');
          let size = element.uri.length;
          

          let arrayFile =  element.uri.split('/');



          element.uri = element.uri.slice(index, size).toString();


          //corte na string da coluna file          
          element.uri = arrayFile.length <= 4 ? 'g' : element.uri;

          //Alteração no front para diferenciar quando chegou apenas no totvs a consulta
          element.request = ((element.request == null || element.request == 1 || element.request == 0)) ? 'Consulta TOTVS' : element.request
          element.response = ((element.response == null || element.response == 0)) ? 'Consulta TOTVS' : element.response

          if(element.uri == 'g'){

            element.uri = 'Consulta TOTVS'
          }          
        })
        this.dataSource.data = obj.data;
        this.loadingSearch = 1;
        this.hasSentences = true;
      }else {
        this.loadingSearch = 2;
        this.dataSource.data = obj.data;
        this.noSentences = true;
      }
    })
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === "") {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      return compare(('' + a[sort.active]).toLowerCase(), ('' + b[sort.active]).toLowerCase(), isAsc);
    });

  }

  refresPage() {
    this.ngOnChanges()
  }
    
    filterChange(column, value, date = false, search = '') {
        let newValue = [];
        switch (column) {
            case 'sql':
                newValue = value.map((elm) => elm.id)
                break;
            case 'method':
                newValue = value.map((elm) => "'"+elm.uri+"'")
                break;
            case 'success':
                newValue = value.map((elm) => elm.value)
                break;
        
            default:
                break;
        }
        console.log(column, value, newValue);
        this.filterform.controls[column].setValue(newValue);
    }
    }


function compare(a: string, b: string, isAsc: boolean) {
  let x, y
  y = a + ''.trim(); // Remove whitespace
  y = y.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  x = b + ''.trim(); // Remove whitespace
  x = x.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return (y.toLowerCase() < x.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
}

