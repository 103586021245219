<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>Página não encontrada</title>
    <!-- Css -->
    <!-- <link rel="stylesheet" href="page404.css"> -->
</head>
<body>
    <div class="background-404">
        <div class="center">
            <img src="../../../assets/images/ant.svg" alt="404">
            <h2 class="title-404">Acesso não autorizado</h2>
            <p class="sub-title-404">
                Acesse esse app através do <b>Rubeus</b>.
            </p>
        </div>
        <svg width="962" height="674" viewBox="0 0 962 674" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                d="M950.867 295.186L751.87 -49.472C737.554 -75.2141 708.921 -92.3755 678.857 -92.3755H282.295C252.231 -92.3755 223.598 -76.6442 209.282 -49.472L11.7167 295.186C-2.59959 320.928 -2.59959 352.391 11.7167 379.563L210.713 724.221C225.03 749.963 253.662 767.124 283.727 767.124H680.288C710.353 767.124 738.985 751.393 753.302 724.221L950.867 379.563C965.183 352.391 965.183 320.928 950.867 295.186Z"
                fill="#0DA6A6" />
        </svg>
    </div>
</body>
</html>