<div mat-dialog-title class="ps-header-dialog" fxLayoutAlign="start center">
    <div fxFlex="" fxLayoutAlign="start center" fxLayout="row" class="ps-dialog-title">
        <div fxFlex="">{{dados.titleDialog}}</div>
        <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
    </div>
</div>
<mat-dialog-content class="container rb-padding-16x20 rb-padding-bottom-0">
    <form action="" [formGroup]="formCategory">
        <div fxLayout="row" fxLayoutGap="27px"> 
            <mat-form-field class="flex1" appearance="fill">
                <mat-label>Nome da categoria</mat-label>
                <input matInput formControlName="titulo" type="text" class="validate"/>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
    <a mat-stroked-button [mat-dialog-close]="null" style="color: black !important;">Cancelar</a>
    <button mat-raised-button [mat-dialog-close]="formCategory.value" [disabled]="!formCategory.valid">Salvar</button>
</mat-dialog-actions>