<div *ngIf="this.noSentences == true && this.loading == 4" fxLayout="column" fxLayoutAlign="space-around center">
    <mat-card style="height: 530px">
        <div>
            <p><img src="../../assets/icons/integracaototvs.svg"></p>
    
            <p class="title">Crie TBCs para realizar consultas no TOTVS!</p>
    
            <p class="description"></p>
            <p style="margin-bottom: 15px">
                <button color="primary" mat-raised-button (click)="openCreateTbcDialog()" class="btn">Criar TBC</button>
            </p>
        </div>
    </mat-card>
    </div>

<div *ngIf="this.hasSentences == true && this.loading == 4" fxLayout="row" fxLayoutAlign="center">     
        <mat-card>
            <app-crud-tbc
                [(receivedTbcData)] = "dataSource"
                [(columnsTbc)] = "columns"
                (clickedTbcEdit) = "getTbcData($event)"
                (clickedTbcCreate) = "openCreateTbcDialog()"
                (clickedTbcDelete) = "deleteTbc($event)">
            </app-crud-tbc>
        </mat-card>
</div>
