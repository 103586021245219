import { HttpClient } from '@angular/common/http';
import { Component, ComponentRef, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-dialog-log-crud',
  templateUrl: './dialog-log-crud.component.html',
  styleUrls: ['./dialog-log-crud.component.scss']
})
export class DialogLogCrudComponent implements OnInit {

  logcrm: FormGroup



  hide = true;
  visible = true;
  removable = true;
  addOnBlur = true;
  selectable = true;
  execute = false;
  private payload: object;
  public dados: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogLogCrudComponent>,
    private service: DataService,
    private router: Router,
    private http:HttpClient
  ) { 
    this.dados = data;
  }

  ngOnInit() {
    this.logcrm = this.formBuilder.group({
      sentence: [ this.data.data != null ? (this.data.data.sentence ? this.data.data.sentence : this.data.data.codconsult) : null],
      uri: [ this.data.data != null ? (this.data.data.uri ? this.data.data.uri : this.data.data.file ) : null],
      created_at: [ this.data.data != null ? formatDate(Date.parse(this.data.data.created_at), 'dd/MM/yyyy', 'en-US') +
      ' às ' +
      formatDate(Date.parse(this.data.data.created_at), 'HH:mm:ss', 'en-US') : null ],
      request: [ this.data.data != null ? this.data.data.request : null ],
      response: [ this.data.data != null ? this.data.data.response : null]  
    });

    const state = localStorage.getItem('state');

    if (state != 'ok') {
      this.router.navigate(['']);
    }

    this.payload = {
      client : localStorage.getItem('client'),
      user : localStorage.getItem('user'),
      jwt : localStorage.getItem('token')
    };
  }

    onNoClick(): void {
      this.dialogRef.close();
    }


   downloadLog() {
    if(this.data.data.file != null){
      window.open(this.data.data.file, '_blank');
    } else {
      alert("Erro ao abrir o arquivo!")
    }
    
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  
}
